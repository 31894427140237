import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { selectAuthState } from '../auth/_selectors/auth.selectors';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CorsHeaders{
    private token: string;
  constructor(private appState: Store<AppState>) {
    this.appState.select(selectAuthState).subscribe((result) => {
      this.token = result.authToken;
    });
  }

  headers(): any {
    const headers = new HttpHeaders()
    .append("Authorization", `Bearer ${this.token}`)
    .append('Content-Type', 'application/json-patch+json')
    .append('accept', 'application/json')
    .append('accept', 'text/plain')
    .append('accept', '*/*')
    return headers;
  }
}
import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ChangeResponsibleRequestType } from '../../../models/changeReponsibleRequestType'
import { RequestType } from '../../../models/requestType'
import { Router, ActivatedRoute } from '@angular/router'
import { ResponsiblesService } from '../../../services/responsiblesServices.service'
import { AlertService } from '../../../../../core/services/alert.service'
import { Responsible } from '../../../models/responsible'
import { NgxSpinnerService } from 'ngx-spinner'
import { ResponsibleRequestTypeLine } from '../../../models/responsibleRequestTypeLine'
import { Line } from '../../../models/line'

@Component({
	selector: 'kt-responsible-request-type-edit',
	templateUrl: './responsible-request-type-edit.component.html',
	styleUrls: ['./responsible-request-type-edit.component.scss'],
})
export class ResponsibleRequestTypeEditComponent implements OnInit {
	responsibleFormGroup: FormGroup
	responsibleRequestType: ChangeResponsibleRequestType
	responsibleRequestTypeResult: ResponsibleRequestTypeLine
	requestType: RequestType[]
	responsible: Responsible[]
	lines: Line[]
	requestTypeId: string
	requestTypeName = ''
	responsibleName = ''
	responsibleId = ''
	selectedFilter = ''
	private id: string
	showLines: boolean
	requestTypeExportId = 'fd094c03-0793-4333-3149-08d83a029f26'
	emptyLineId = '00000000-0000-0000-0000-000000000000'

	constructor(
		private formsBuilder: FormBuilder,
		private router: Router,
		private activateRoute: ActivatedRoute,
		private responsibleService: ResponsiblesService,
		private alertService: AlertService,
		private spinnerService: NgxSpinnerService
	) {}

	ngOnInit() {
		this.activateRoute.params.subscribe((params) => {
			if (params['id'] == undefined || params['id'] == 0) {
				this.alertService
					.error(
						'No se pudo obtener el id de la relacion Tipo Solicitud y Responsable será redireccionado al Listado',
						'Aviso!'
					)
					.then((result) => {
						if (result.value) {
							this.router.navigate(['/mardomgoadmin/responsible-request-type'])
						}
					})
			}
			this.id = params['id']
		})

		this.responsibleFormGroup = this.formsBuilder.group({
			id: 0,
			responsibleId: ['', Validators.required],
			requestTypeId: ['', Validators.required],
			lineId: ['00000000-0000-0000-0000-000000000000'],
			active: [false],
		})

		this.getRequestType()
		//this.getResponsibleRequestType(this.id);
	}

	getRequestType() {
		this.responsibleService.getRequestType().subscribe(
			(list) => {
				this.requestType = list
			},
			(error) => {
				console.log(error)
				switch (error.status) {
					case 401:
						return this.alertService
							.error('No tiene los permisos para consultar este recurso')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case 403:
						return this.alertService
							.error('No tiene los permisos para consultar este recurso')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case 500:
						return this.alertService
							.error('Oop! Ha ocurrido un error')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case 0:
						return this.alertService
							.error('Oops! Ha ocurrido un error.', 'Error')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case undefined:
						return this.alertService
							.error('Oops! Ha ocurrido un error.', 'Error')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
				}
			}
		)
	}

	getResponsibleRequestType(id: string) {
		this.spinnerService.show()
		this.responsibleService.getResponsibleRequestTypeById(this.id).subscribe(
			(response) => {
				this.loadForm(response)
			},
			(error) => (error: { status: any }) => {
				this.spinnerService.hide()
				switch (error.status) {
					case 401:
						return this.alertService
							.error('No tiene los permisos para consultar este recurso')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case 403:
						return this.alertService
							.error('No tiene los permisos para consultar este recurso')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case 500:
						return this.alertService
							.error('Oop! Ha ocurrido un error')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case 0:
						return this.alertService
							.error('Oops! Ha ocurrido un error.', 'Error')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case undefined:
						return this.alertService
							.error('Oops! Ha ocurrido un error.', 'Error')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
				}
			}
		)
	}

	loadForm(responsibleRequestTypeLine: ResponsibleRequestTypeLine) {
		this.responsibleFormGroup.patchValue({
			id: responsibleRequestTypeLine.id,
			responsibleId: responsibleRequestTypeLine.responsibleId,
			responsibleName: responsibleRequestTypeLine.responsibleName,
			requestTypeId: responsibleRequestTypeLine.requestTypeId,
			lineId: responsibleRequestTypeLine.lineId,
		})

		this.responsibleId = responsibleRequestTypeLine.responsibleId
		this.responsibleName = responsibleRequestTypeLine.responsibleName

		this.getLines(responsibleRequestTypeLine.requestTypeId)

		this.spinnerService.hide()
	}

	onSubmit() {
		if (this.responsibleFormGroup.invalid) {
			return
		}
		this.changeRequestType()
	}

	changeRequestType() {
		this.responsibleRequestType = this.responsibleFormGroup
			.value as ChangeResponsibleRequestType
		this.responsibleRequestType.id = this.id

		if (
			this.responsibleRequestType.requestTypeId == this.requestTypeExportId &&
			this.responsibleRequestType.lineId == this.emptyLineId
		) {
			this.alertService.warning('Debe seleccionar una línea')
			return
		} else if (
			this.responsibleRequestType.requestTypeId != this.requestTypeExportId &&
			this.responsibleRequestType.lineId != this.emptyLineId
		) {
			this.responsibleRequestType.lineId = this.emptyLineId
		}

		this.responsibleService
			.changeRequestTypeToResponsible(this.responsibleRequestType)
			.subscribe(
				(responsibleRequestType) => this.onSaveSuccess(),
				(error) => {
					this.alertService.error(error.error.errors[0].message)
				}
			)
	}

	onSaveSuccess() {
		this.alertService
			.info('Cambios realizados satisfactoriamente')
			.then((result) => {
				if (result.value) {
					this.router.navigate(['/mardomgoadmin/responsible-request-type'])
				}
			})
	}

	onChangeRequestType() {
		this.requestTypeId = this.selectedFilter

		if (this.requestTypeId == this.requestTypeExportId) {
			this.getLines(this.requestTypeId)
		} else {
			;(this.lines = null), (this.showLines = false)
		}
	}

	getLines(requestTypeId: string) {
		this.responsibleService.getLinesByRequestTypeId(requestTypeId).subscribe(
			(list) => {
				;(this.lines = list), (this.showLines = true)
			},
			(error) => {
				;(this.lines = null), (this.showLines = false)
			}
		)
	}
}

import { Component, ViewChild, OnInit } from '@angular/core'
import { Employee } from '../../models/employee'
import { EikonEmployeesService } from '../../services/eikon-employees.service'
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material'
import { AlertService } from '../../../../core/services/alert.service'
import { NgxSpinnerService } from 'ngx-spinner'
import { Router } from '@angular/router'
import { EmployeesaccountService } from '../../services/employeesaccount.service'
import { LogService } from '../../services/log.service'
import { Screen } from '../../models/enum/screen'
import { Action } from '../../models/enum/action'
import { Log } from '../../models/log'

@Component({
	selector: 'kt-eikon-employees',
	templateUrl: './eikon-employees.component.html',
	styleUrls: ['./eikon-employees.component.scss'],
})
export class EikonEmployeesComponent implements OnInit {
	searchText
	filterInput: string = ''
	selectedFilter: number = 0
	showResult: boolean = false
	displayedColumns = [
		'code',
		'fullName',
		'email',
		'workPosition',
		'department',
		'options',
	]
	dataSource: MatTableDataSource<Employee> = new MatTableDataSource()

	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator
	@ViewChild(MatSort, { static: true }) sort: MatSort

	constructor(
		private eikonEmployeeService: EikonEmployeesService,
		private alertService: AlertService,
		private employeeService: EmployeesaccountService,
		private router: Router,
		private spinnerService: NgxSpinnerService,
		private logService: LogService
	) {}
	ngOnInit(): void {
		this.logService.post(<Log>{
			ScreenId: Screen.CreacionUsuarioEmpleadoEikon,
			ActionId: Action.AccederAPantalla,
			Json: '',
		})
	}

	async getResults() {
		if (this.selectedFilter == 0) {
			return
		}
		this.spinnerService.show()
		const response = await this.getEikonEmployees()
		this.spinnerService.hide()
		if (response.length == 0) {
			this.alertService.info(
				'No existen resultados para los criterios de su búsqueda.'
			)
			this.showResult = false
			return
		}
		this.showResult = true
		if (this.dataSource) {
			this.dataSource.disconnect()
		}

		this.dataSource.paginator = this.paginator
		this.dataSource.sort = this.sort
		this.dataSource = new MatTableDataSource(response)
		console.log(this.dataSource)
		this.spinnerService.hide()
	}

	createUser(employee: Employee) {
		this.logService.post(<Log>{
			ScreenId: Screen.CreacionUsuarioEmpleadoEikon,
			ActionId: Action.CreacionDeUsuarioInterno,
			Json: JSON.stringify(employee),
		})
		this.alertService.question(() => {
			this.spinnerService.show()
			this.employeeService.createEmployeeAccounts(employee.code).subscribe(
				() => {
					this.spinnerService.hide()
					this.alertService
						.success('Usuario creado correctamente', 'Operación exitosa')
						.then(() => {
							this.router.navigateByUrl('/mardomgoadmin/internalusers')
						})
				},
				(error) => {
					this.alertService.error(
						error.error.errors[0].message,
						'Operación fallida'
					)
					this.spinnerService.hide()
				}
			)
		}, `¿Esta seguro que desea crear a ${employee.fullName} como usuario de Mardom Go?`)
	}

	clear() {
		this.filterInput = ''
		this.selectedFilter = 0
		this.showResult = false
	}

	async getEikonEmployees() {
		let result: Employee[] = []
		if (this.selectedFilter == 1) {
			await this.eikonEmployeeService
				.getEikonEmployeeByCode(this.filterInput)
				.then((item) => {
					result.push(item),
						this.logService.post(<Log>{
							ScreenId: Screen.CreacionUsuarioEmpleadoEikon,
							ActionId: Action.BusquedaDeEmpleadoEnEikonPorCodigo,
							Json: JSON.stringify(item),
						})
				})
				.catch((error) => {
					this.spinnerService.hide()
					switch (error.status) {
						case 401:
							return this.alertService
								.error('No tiene los permisos para consultar este recurso')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
						case 403:
							return this.alertService
								.error('No tiene los permisos para consultar este recurso')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
						case 500:
							return this.alertService
								.error('Oop! Ha ocurrido un error')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
					}
				})
		}

		if (this.selectedFilter == 2) {
			await this.eikonEmployeeService
				.getEikonEmployeeByName(this.filterInput)
				.then((list) => {
					result = list
					this.logService.post(<Log>{
						ScreenId: Screen.CreacionUsuarioEmpleadoEikon,
						ActionId: Action.BusquedaDeEmpleadoEnEikonPorNombre,
						Json: JSON.stringify(list),
					})
				})
				.catch((error) => {
					this.spinnerService.hide()
					switch (error.status) {
						case 401:
							return this.alertService
								.error('No tiene los permisos para consultar este recurso')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
						case 403:
							return this.alertService
								.error('No tiene los permisos para consultar este recurso')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
						case 500:
							return this.alertService
								.error('Oop! Ha ocurrido un error')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
					}
				})
		}

		result = result.filter((item) => item.status === 1)
		return result
	}
}

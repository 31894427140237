import { Component, OnInit } from '@angular/core';
import {PerfilEmpresaService} from '../../../services/perfil-empresa.service'
import { NgxSpinnerService } from 'ngx-spinner'
import { AlertService } from '../../../../../core/services/alert.service';
import { Router } from '@angular/router';
import { DatashareService } from '../../../services/datashare.service';

@Component({
  selector: 'kt-all-companies-list',
  templateUrl: './all-companies-list.component.html',
  styleUrls: ['./all-companies-list.component.scss']
})
export class AllCompaniesListComponent implements OnInit {
public loading:Boolean=false
public companylist:any=[];
  constructor(
    private perfilEmpresaService:PerfilEmpresaService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private router: Router,
    private datashareService:DatashareService,
  ) {}

  ngOnInit() {
    this.getUserAllCompany();
  }


  getUserAllCompany(){
    this.spinnerService.show();
    this.perfilEmpresaService.getAllUserCompany().subscribe((response)=>{
      this.companylist=response.clients;
      this.spinnerService.hide();
    },(error) => {
      // this.alertService.error('Oops! Ha ocurrido un error.', 'Error')
      // .then(() => {
      //   this.router.navigateByUrl('/mardomgoadmin/dashboard')
      // })
      this.spinnerService.hide();
    });
  }

  getcompanydata(item){
    this.datashareService.setrncnumber(item);
    this.router.navigate(["/mardomgoadmin/perfil-empresa"]) 
  }

}

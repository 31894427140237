export class customerMardomGo{
    id :string   
    fullName:string
    email:string
    phone:string
    officePhone:string
    asociatedCompany:string
    active:boolean
    createdDate:Date
    asociatedCompanyName:string    
    companyName:string    
    clientId:string
    syncstatus:boolean
}

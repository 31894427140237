export class accountExecutive{
    adminEmployee:string;
    code:string;
    department:string;
    email:string;
    fullName:string;
    id:string;
    lastName:string;
    name:string;
    status:Boolean;
    workPosition:string;
}
import { Component, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { customerMardomGo } from '../../../models/customerMardomGo';
import { ResponsiblesService } from '../../../services/responsiblesServices.service';
import { AlertService } from '../../../../../../../src/app/core/services/alert.service';
import { CustomerMardomgoService } from '../../../services/customer.service';
import { EventEmitter } from 'events';
import { NgxSpinnerService } from 'ngx-spinner';
import { LogService } from '../../../services/log.service';
import { Log } from '../../../models/log';
import { Screen } from '../../../models/enum/screen';
import { Action } from '../../../models/enum/action';
import {assignToCustomer} from '../../../models/assignToCustomer'
import {DatashareService} from '../../../services/datashare.service';

@Component({
  selector: 'customer-wizard',
  styles: [`
    .mat-stepper-horizontal {
        margin-top: 8px;
    }
    
    .mat-form-field {
        margin-top: 16px;
    }
  `],
  templateUrl: './customer-wizard.component.html',
})
export class customerWizardComponent {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  customerId: string;
  customerName: string;
  customer: customerMardomGo = new customerMardomGo()
  ResponsibleCustomer: any
  responsableName: string
  customerInfo: any;
  accountExecutive:any;
  @Output() notifyParent: EventEmitter = new EventEmitter()
  assignToCustomer: any;
  companyId:string;
  selectedcompany:any;

  constructor(private _formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private responsibleService: ResponsiblesService,
    private alertService: AlertService,
    private customerService: CustomerMardomgoService,
    private spinnerService: NgxSpinnerService,
    private logService : LogService,
  ) { }
  ngOnInit() {   
    this.logService.post(<Log>{
			ScreenId : Screen.EnlaceClienteEmpresa,
			ActionId : Action.AccederAPantalla,
			Json : ''
		  });
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }
  close() {
    this.activeModal.close()
  }

  save() {
    //this.syncAuthUser();
     this.spinnerService.show();
    if (this.customerInfo) {
      this.customerService.activateCustomer(this.customerInfo).subscribe(
        (response) => {
          //this.saveResponsible() ,
          //this.assignExceutiveToCustomer() ,
          this.sendEmail()
          this.spinnerService.hide();
          this.logService.post(<Log>{
            ScreenId : Screen.EnlaceClienteEmpresa,
            ActionId : Action.AsignacionDeCuentaACliente,
            Json : JSON.stringify(this.customerInfo)
            });
            this.alertService.success("Información guardada exitosamente.").then(() => {
              window.location.reload()
            })    
        },
        (error) => {
          this.spinnerService.hide();
          if (error.status == 403 || error.status == 401)
            this.alertService.error('No tiene permisos para realizar la acción')
          this.alertService.error(error.error.errors[0].message)
        }
      ); 
    } else {
      //this.saveResponsible();
      //this.assignExceutiveToCustomer();
    } 
  }
  saveResponsible() {
    this.responsibleService.assignResponsibleToCustomer(this.ResponsibleCustomer)
      .subscribe(
        () => {
          this.spinnerService.hide();
          this.logService.post(<Log>{
            ScreenId : Screen.EnlaceClienteEmpresa,
            ActionId : Action.AsignacionDeResponsableACliente,
            Json : JSON.stringify(this.customerInfo)
            });
          //this.sendEmail()
          this.alertService.success("Información guardada exitosamente.").then(() => {
            window.location.reload()
          })
        },
        (error) => {
          this.spinnerService.hide();
          if (error.status == 403 || error.status == 401) {
            this.alertService.error('No tiene permisos para realizar la acción')
            return;
          }
          if (error.status == 400) {
            this.alertService.warning(error.error.errors[0].message);
            return;
          }
          try {
            this.alertService.error(error.error.errors[0].message);
          }
          catch {
            this.alertService.error('Ha ocurrido un error al realizar dicha acción');
          }
        });
  }

  sendEmail() {
    let company = [];
    let rnc = []
    this.customerInfo.assignCompanyList.forEach(element => {
      company.push(element.companyName);
      rnc.push(element.rnc);
    });
    let payload = {
      empresa: company.join(","),
      rnc:rnc.join(","),
      email: this.customerInfo.assignCompanyList[0].userEmail,
      fullName:this.customerInfo.assignCompanyList[0].fullName
    }
    this.responsibleService.sendEmail(payload)
      .subscribe(data => {

      })
  }

  getDataAssociateCostumer(data: any) {    
    this.customerInfo = data.customerInfo;
    this.customerId = data.customerInfo
    this.customerName = data.asociatedCompanyName    
  }
  getDataResponsible(data: any) {
    this.responsableName = data.responsibleName;
    this.ResponsibleCustomer = data.ResponsibleCustomer;
    this.assignToCustomer=data.assignToCustomer;
  }

  assignExceutiveToCustomer(){
    this.responsibleService.assignExceutiveToCustomer(this.assignToCustomer).subscribe();
  }

  async syncAuthUser(){
    console.log(this.customerInfo.assignCompanyList[0]);
     if(this.customer.syncstatus==false){
      let payload=[{
      "IdAuth0":this.customerInfo.assignCompanyList[0].id,
			"Name":this.customerInfo.assignCompanyList[0].firstName,
			"email":this.customerInfo.assignCompanyList[0].email,
      "LastName":this.customerInfo.assignCompanyList[0].lastName,
			"External":true }]
      await this.customerService.syncAuthUser(payload).subscribe((response)=>{
     },(error)=>{
      console.log(error)
     })
    }}
}

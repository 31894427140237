import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../../../../../app/core/services/alert.service';
import { internaluser } from '../../../models/internal-users/internaluser';
import { role } from '../../../models/internal-users/role';
import { EmployeesaccountService } from '../../../services/employeesaccount.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-details-internal-users',
  templateUrl: './details-internal-users.component.html',
  styleUrls: ['./details-internal-users.component.scss']
})
export class DetailsInternalUsersComponent implements OnInit {

  formInternalUsers: FormGroup;
  role: role[] = new Array<role>();
  private userId: string;

  constructor(private formsBuilder: FormBuilder, private spinnerService: NgxSpinnerService, private activateRoute: ActivatedRoute, private alertService: AlertService, private router: Router, private employeesAccountService: EmployeesaccountService) { this.CreateFormInternalsUsers(); }

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      if (params["id"] == undefined || params["id"] == 0) {
        this.alertService.error("No se pudo obtener el id del ejecutivo será redireccionado al Listado", "Aviso!").then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/internalusers"]) } })
      }
      this.userId = params["id"];
    })
    this.getInternalUserById();
  }
  getInternalUserById() {
    this.spinnerService.show();
    this.employeesAccountService.getEmployeeAccountById(this.userId).subscribe((response) => {
      this.spinnerService.hide();
      this.LoadFormsInternalsUsers(response as internaluser), (this.role = response.role)
    },
       (error) => {
         this.spinnerService.hide();
         console.log(error)
        switch (error.status) {
          case 401:
            return this.alertService.info(
              'No tiene los permisos para realizar esta acción.', 'Aviso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/internalusers"]) } })
          case 400:
            return this.alertService.info(
            'Ha ocurrido un error Obteniendo la informacion del usuario', 'Aviso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/internalusers"]) } })
          case 500:
            return this.alertService.error(
              'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/internalusers"]) } })
          case 403:
            return this.alertService.info(
              'No tiene los permisos para realizar esta acción..', 'Aviso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/internalusers"]) } })
          case 0:
            return this.alertService.error(
              'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/internalusers"]) } })
          case undefined:
            return this.alertService.error(
              'Oops! Ha ocurrido un error realizacion esta acción', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/internalusers"]) } })
        }
      })
  }

  CreateFormInternalsUsers() {
    this.formInternalUsers = this.formsBuilder.group({
      id: ['', Validators.required],
      code: ['', Validators.required],
      departament: ['', Validators.required],
      email: ['', Validators.required],
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      fullName: ['', Validators.required],
      workPosition: ['', Validators.required],
      role: ['', Validators.required],
      adminEmployee: ['', Validators.required],
      status: [false]
    });
  }

  LoadFormsInternalsUsers(intenalUsers: internaluser) {
    this.formInternalUsers.patchValue({
      id: intenalUsers.id,
      code: intenalUsers.code,
      departament: intenalUsers.department,
      email: intenalUsers.email,
      name: intenalUsers.name,
      lastName: intenalUsers.lastName,
      fullName: intenalUsers.fullName,
      workPosition: intenalUsers.workPosition,
      role: intenalUsers.role,
      adminEmployee: intenalUsers.adminEmployee,
      status: intenalUsers.status
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kt-assign-rol',
  templateUrl: './assign-rol.component.html',
  styleUrls: ['./assign-rol.component.scss']
})
export class AssignRolComponent implements OnInit {
	loading = false
  constructor() { }

  ngOnInit() {
    
  }


}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatashareService {
public CompanyId:any;
public rncnumber:any;
public customerDetails:any;
  constructor() { }

  setCompanyId(Id) {
    this.CompanyId=Id;
  }

  getCompanyId(){
    return this.CompanyId;
  }

  setrncnumber(Id) {
    this.rncnumber=Id;
  }

  getrncnumber(){
    return this.rncnumber;
  }

  setcustomerDetails(item) {
    this.customerDetails=item;
  }

  getcustomerDetails(){
    return this.customerDetails;
  }


}

// Angular
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationExtras,
  Router,
  RouterStateSnapshot
} from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Module reducers and selectors
import { AppState } from '../../reducers';
import { currentAccessToken, currentUserPermissions } from '../_selectors/auth.selectors';
import { Permission } from '../_models/permission.model';
import { find } from 'lodash';
import { currentAuthToken } from '..';
import * as jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class ModuleGuard implements CanActivate {
  roles: string;
  isAdmin = false;
  constructor(private store: Store<AppState>, private router: Router) {
    let authToken;
    this.store.select(currentAccessToken).subscribe((res) => (authToken = res));

    const authTokenInfo = jwt_decode(authToken);
    this.roles = authTokenInfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']

    if (this.roles.indexOf('admin') > -1) {
      this.isAdmin = true;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(currentUserPermissions),
      map((permissions: Permission[]) => {
        if (state.url == '/mardomgoadmin/request') {
          return this.isAdmin;
        }
        return true;
      }),
      tap(hasAccess => {
        if (!this.isAdmin) {
          const errorDescription = 'El usuario que intenta acceder no posee los permisos necesarios de Administrador';
          const navigationExtra: NavigationExtras = { state: { errorDescription: errorDescription } }
          this.router.navigate(['error/401'], navigationExtra);
        }
        else if (!hasAccess) {
          this.router.navigateByUrl('/error/403');
        }
      })
    );

  }
}

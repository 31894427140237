// Angular
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationExtras,
  Router,
  RouterStateSnapshot
} from '@angular/router';
// RxJS
import { Observable, zip } from 'rxjs';
import { tap, map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../core/reducers/';
import { isLoggedIn, isLoggingIn, selectAuthState } from '../_selectors/auth.selectors';
import { AuthService } from '../_services/auth.service';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const loggedInObservable = this.store.pipe(select(isLoggedIn));
    const loggingInObservable = this.store.pipe(select(isLoggingIn));
    const tokenPayload = this.store.pipe(select(selectAuthState));
    return zip(loggedInObservable, loggingInObservable, tokenPayload)
      .pipe(
        tap(([loggedIn, loggingIn, token]) => {          
          if (!(loggedIn || loggingIn)) {
            this.authService.logindirect(state.url,false);
          }
          if(token && token.authToken){
            let data = jwt_decode(token.authToken);
            if (data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"][0].indexOf('Client') > -1){
              const errorDescription =
              'El usuario que intenta acceder no posee los permisos necesarios de Administrador y/o Coordinador'
              const navigationExtra: NavigationExtras = {
                state: { errorDescription: errorDescription },
              }
              loggedIn = false;
              this.router.navigateByUrl('error/401', navigationExtra)
            }
          }
        }),
        map(([loggedIn]) => {
          return loggedIn;
        })
      );
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { selectAuthState } from '../../../../app/core/auth/_selectors/auth.selectors'
import { AppState } from '../../../../app/core/reducers'
import { CorsHeaders } from '../../../../app/core/services/corsHeader'
import { endpoint, environment } from '../../../../environments/environment'
import { Log } from '../models/log'
import * as jwt_decode from 'jwt-decode'
import { User } from '../../../../app/core/auth/_models/user.model'


@Injectable({
	providedIn: 'root',
})
export class LogService {
	headers: HttpHeaders
	baseUrl: string
	user: User = new User();
	tokenData: any

	constructor(
		private http: HttpClient,
		private store: Store<AppState>,
		private corsHeader: CorsHeaders
	) {
		this.user = new User();
		this.store.select(selectAuthState).subscribe((result) => {
			this.user = result.user
			this.tokenData = jwt_decode(result.authToken)
		})
	}

	post(payload: Log) {
		let result = this.setPayloadSessionData(payload)
		if(result){
			this.http
				.post(`${endpoint.Audit}`, payload, {
					headers: this.corsHeader.headers(),
				})
				.subscribe(
					() => {},
					() => console.error('Could not save log')
				)
		}
	}

	private setPayloadSessionData(payload: Log) {
		if(!this.user) return false;

		payload.UserName = this.user.fullname
		payload.Email = this.user.email
		payload.ClientId =
			this.tokenData['https://mardom.com/clientId'] == ''
				? null
				: this.tokenData['https://mardom.com/clientId']
		payload.Browser = this.getBrowser()
		payload.RoleName = this.tokenData[
			'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
		][0]

		return true;
	}

	private getBrowser(): string {
		const agent = window.navigator.userAgent.toLowerCase()
		switch (true) {
			case agent.indexOf('edge') > -1:
				return 'Edge'
			case agent.indexOf('opr') > -1 && !!(<any>window).opr:
				return 'Opera'
			case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
				return 'Chrome'
			case agent.indexOf('trident') > -1:
				return 'Ie'
			case agent.indexOf('firefox') > -1:
				return 'Firefox'
			case agent.indexOf('safari') > -1:
				return 'Safari'
			default:
				return 'Other'
		}
	}
}

import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { endpoint } from '../../../../../src/environments/environment';
import { CorsHeaders } from '../../../../../src/app/core/services/corsHeader';

@Injectable({
    providedIn: 'root'
  })
export class RoleService {          
    constructor(private _httpClient: HttpClient, private corsHeader:CorsHeaders) {                 
    }

    getRoles(){
        return this._httpClient.get<any>(endpoint.roleEndpoint, {headers: this.corsHeader.headers()});
    }
    
    getRolePermissions(id:string){
        return this._httpClient.get<any>(endpoint.roleEndpoint+`/${id}/permissions`, {headers: this.corsHeader.headers()});        
    }

    getPermissions(){
        return this._httpClient.get<any>(endpoint.permissionEndpoint, {headers: this.corsHeader.headers()});
    }
    
    savePermissions(id: string, permissions: any[]) {
        return this._httpClient.post<any>(endpoint.roleEndpoint+`/${id}/permissions`, permissions, {headers: this.corsHeader.headers()});
    }

    deletePermissions(id: string, permissions: any[]) {
        return this._httpClient.put<any>(endpoint.roleEndpoint+`/${id}/permissions`, permissions, {headers: this.corsHeader.headers()});
    }        
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  accessTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  idTokenKey: 'auth6c8cdbc3b812e663429f67a5697cceb7',
  antiCSRFTokenKey: 'auth52af7224499fe176dd223135a412a0f6',
  tokensExpirationDateKey: 'exp58c6607aca90dedfc78b8fe52b65d5ac',
  auth: {
    clientID: '#{clientId}#',
    domain: '#{domain}#',
    connection: '#{connection}#',
    audience: '#{audience}#',
    redirectUri: '#{redirectUri}#'
  }
};

export const config: any = {
  //ApiUrl:'#{ApiUrl}#',
	//ApiUrl:'https://go.qa.mardom.com/api',
  //ApiUrl:'https://go.uat.mardom.com/api',
  ApiUrl:'https://go.mardom.com/api',
  LocalHostApi: 'http://localhost:5002',
  LocalMail: 'http://localhost:44325',
  modalConfig: {
	  size: 'xl',
	backdrop: 'static',
	keyboard: false,
  } as NgbModalOptions,
};

export const endpoint: any = {
  customer:`${config.ApiUrl}/account-management/customerAccount`,
  company:`${config.ApiUrl}/account-management/client`,
  roleEndpoint: `${config.ApiUrl}/account-management/role`,  
  permissionEndpoint: `${config.ApiUrl}/account-management/permission`,
  userAccountEndpoint: `${config.ApiUrl}/account-management/useraccount`,
  eikonActiveEmployeesEndPoint: `${config.ApiUrl}/eikon-employees/employees/ActiveEmployee`,
  eikonEmployeesEndPoint: `${config.ApiUrl}/eikon-employees/employees`,  
  responsibleEndPoint: `${config.ApiUrl}/Responsible-management/Responsible/`,
  requestTypeEndPoint: `${config.ApiUrl}/Responsible-management/RequestType`,
  responsibleRequestTypeEndPoint: `${config.ApiUrl}/Responsible-management/ResponsibleRequestType`,
  assignRequestTypeToResponsibleEndPoint: `${config.ApiUrl}/Responsible-management/ResponsibleRequestType/AssignRequestTypeToResponsible/`,
  responsibleRequestTypeByIdEndPoint: `${config.ApiUrl}/Responsible-management/ResponsibleRequestType/ResponsibleRequestTypeById/`,
  changeRequestTypeToResponsibleEndPoint: `${config.ApiUrl}/Responsible-management/ResponsibleRequestType/ChangeRequestTypeToResponsible/`,
  lineEndPoint: `${config.ApiUrl}/Responsible-management/Lines/`,
  lineByRequestTypeIdEndPoint: `${config.ApiUrl}/Responsible-management/Lines/LineByRequestTypeId/`,
  changeResponsibleLineByIdEndPoint: `${config.ApiUrl}/Responsible-management/ResponsibleLine/`,
  employeeAccount : `${config.ApiUrl}/account-management/EmployeesAccount`,
  responsibleCustomerEndPoint : `${config.ApiUrl}/Responsible-management/ResponsibleCustomer`,
  customerEndPoint : `${config.ApiUrl}/Responsible-management/Client`,
  assignResponsibleToCustomerEndPoint: `${config.ApiUrl}/Responsible-management/ResponsibleCustomer/AssignResponsibleToCustomer`,
  changeResponsibleToCustomerEndPoint: `${config.ApiUrl}/Responsible-management/ResponsibleCustomer/ChangeCustomerToResponsible`,
  requestService: `${config.ApiUrl}/gateway/trackandtrace/RequestService`,
  billVoucher: `${config.ApiUrl}/gateway/trackandtrace/BillVoucher`,
  Audit: `${config.ApiUrl}/general-information/Audit`,
  responsibleCustomerByIdEndPoint: `${config.ApiUrl}/Responsible-management/ResponsibleCustomer/ResponsibleRequestTypeCustomerById/`,
  assignToCustomer:`${config.ApiUrl}/account-management/CustomerAccount/AssignExceutiveToCustomer`,
  sendMail:`${config.ApiUrl}/account-management/CustomerAccount/SendComanyAssignEmail?`,
  getCompany:`${config.ApiUrl}/account-management//CustomerAccount/GetListByCompanyCode?`,
  GetUserlistUserId:`${config.ApiUrl}/account-management/user/getUserCompanies?userid=`,
  assignCompanyToUser:`${config.ApiUrl}/account-management/user/assignCompanyToUser?getidfromdb=false`,
  syncAuthUser:`${config.ApiUrl}/account-management/User`,
  getCountry: `${config.ApiUrl}/general-Information/country`,
  industryType: `${config.ApiUrl}/general-Information/industrytype`,
  personTitle: `${config.ApiUrl}/general-Information/personTitle`,
  inactivecustomer:`${config.ApiUrl}/account-management/CustomerAccount/InactiveUser`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import {
	Component,
	OnInit,
	ViewChild,
	Output,
	EventEmitter,
	Input,
	ChangeDetectorRef,
} from '@angular/core'
import { AlertService } from '../../../../../core/services/alert.service'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { CustomerMardomgoService } from '../../../services/customer.service'
import { customerMardomGo } from '../../../models/customerMardomGo'
import { LogService } from '../../../services/log.service'
import { Screen } from '../../../models/enum/screen';
import { Action } from '../../../models/enum/action';
import { Log } from '../../../models/log'
@Component({
	selector: 'associate-customer-app',
	templateUrl: './associate-customer.component.html',
	styleUrls: ['./associate-customer.component.scss']

})
export class AssociateCustomerComponent implements OnInit {
	@Output() notifyParent: EventEmitter<any> = new EventEmitter()
	@Input() item: customerMardomGo = new customerMardomGo()
	companyName = ''
	companies = []
	selectedCompany: any = [];
	loadCompanylist: any
	divShown = true;
	title = ''
	loading = false

	constructor(
		private alertService: AlertService,
		public activeModal: NgbActiveModal,
		private customerService: CustomerMardomgoService,
		private logService: LogService,
		private cd: ChangeDetectorRef,
	) { }

	ngOnInit() {
		this.companies = []
		this.getCompany();
		this.loadCompany();
		this.title = this.item.clientId ? 'Modificar empresa enlazada a usuario' : 'Enlazar empresa a usuario';
	}

	selectCompany(item) {
		//	this.selectedCompany = item
		if (typeof this.selectedCompany.find(x => x.id == item.id) == 'undefined') {
			this.selectedCompany.push(item)
		  }
		this.cd.detectChanges();
		this.companies = []
		this.companyName = ''
		this.associateCompany();
		this.divShown = true;
	}

	loadCompany() {
		let getCompany: any = this.item
		let ClientIds:any = []
		getCompany.userAssociatedCompanies.forEach(element => {
			ClientIds.push(element.clientid);
		});

		if (ClientIds.length != 0) {
			this.customerService
				.getCompanyById({ClientIds})
				.subscribe((response) => {
					// this.selectedCompany=response
					//this.loadCompanylist = response;
					this.selectedCompany = response
					this.associateCompany();
				})
		}
	}

	getCompany() {
		if (!this.companyName) return (this.companies = [])
		if (this.companyName.length >= 3) {
			//	this.selectedCompany = null
			this.companyName = !this.companyName ? '' : this.companyName
			this.customerService.getCompanyByName(this.companyName).subscribe(
				(response) => {
					this.companies = response
				},
				(error) => {
					if (error.status == 403 || error.status == 401)
						this.alertService.error(
							'No tiene permisos para consultar los clientes'
						)
					this.alertService.error(error.error.errors[0].message)
				}
			)
		}
		this.divShown = false;
	}

	associateCompany() {
		let assignCompanyList = []
		this.selectedCompany.forEach(element => {
			let data = {
				id: this.item.id,
				active: true,
				clientId: element.id,
				userEmail: this.item.email,
				companyName:element.name,
				rnc:element.rnc,
				fullName:this.item.fullName
			}
			assignCompanyList.push(data)
		});
		const dataToSend = {
			customerInfo: {assignCompanyList},
			asociatedCompanyName: this.selectedCompany
		}
		this.notifyParent.emit(dataToSend)
	}

	confirmAsociatedCompany() {
		this.alertService.question(() => {
			this.associateCompany()
		}, `Seguro que desea enlazar este cliente a la empresa ${this.selectedCompany.name}`)
	}

	close() {
		this.activeModal.close()
	}
}

import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';
import { Permission } from './permission.model';

export class User extends BaseModel {
  id?: string;
  username?: string;
  password?: string;
  email?: string;
  pic?: string;
  fullname?: string;
  occupation?: string;
  companyName?: string;
  phone?: string;
  address?: Address;
  permissions: Permission[];
  socialNetworks?: SocialNetworks;

  clear(): void {
    this.id = undefined;
    this.username = '';
    this.password = '';
    this.email = '';
    this.fullname = '';
    this.pic = './assets/media/users/default.jpg';
    this.occupation = '';
    this.companyName = '';
    this.permissions = [];
    this.phone = '';
    this.address = new Address();
    this.address.clear();
    this.socialNetworks = new SocialNetworks();
    this.socialNetworks.clear();
  }
}

import { Action } from '@ngrx/store';

export enum ErrorActionsTypes {
  ErrorCapture = '[Error Capture] Action',   
}

export class ErrorCapture implements Action {
    readonly type = ErrorActionsTypes.ErrorCapture;
    constructor(public payload: { error: string, errorDescription: string }) { }
}

export type ErrorActions = ErrorCapture
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Employee } from '../models/employee';
import { endpoint } from '../../../../environments/environment';
import { AppState } from '../../../core/reducers';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../../core/auth/_selectors/auth.selectors';
import { CorsHeaders } from '../../../core/services/corsHeader';

@Injectable({
  providedIn: 'root'
})
export class EikonEmployeesService {

  constructor(private http: HttpClient, private corsHeader:CorsHeaders) {}

  getEikonEmployee(): Observable<Employee[]> {    
    return this.http.get<Employee[]>(endpoint.eikonActiveEmployeesEndPoint, {headers:this.corsHeader.headers()});
  }

  getEikonEmployeeByCode(codigoEikon: string): Promise<Employee> {    
    return this.http.get<Employee>(endpoint.eikonEmployeesEndPoint+`/GetByCode/${codigoEikon}`, {headers:this.corsHeader.headers()}).toPromise();
  }

  getEikonEmployeeByName(Name: string): Promise<Employee[]> {    
    return this.http.get<Employee[]>(endpoint.eikonEmployeesEndPoint+`/GetByName/${Name}`, {headers:this.corsHeader.headers()}).toPromise();
  }
}

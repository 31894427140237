import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AlertService } from '../../../../../core/services/alert.service'
import { Responsible } from '../../../models/responsible'
import { ResponsiblesService } from '../../../services/responsiblesServices.service'
import { RequestType } from '../../../models/requestType'
import { Router } from '@angular/router'
import { AssingResponsibleRequestType } from '../../../models/assingReponsibleRequestType'
import { NgxSpinnerService } from 'ngx-spinner'
import { Line } from '../../../models/line'
import * as forge from 'node-forge'

@Component({
	selector: 'kt-responsible-request-type-create',
	templateUrl: './responsible-request-type-create.component.html',
	styleUrls: ['./responsible-request-type-create.component.scss'],
})
export class ResponsibleRequestTypeCreateComponent implements OnInit {
	responsibleFormGroup: FormGroup
	responsibleRequestType: AssingResponsibleRequestType = new AssingResponsibleRequestType()
	responsible: Responsible[]
	requestType: RequestType[]
	lines: Line[]
	submitted = false
	requestTypeId: string
	responsibleId = ''
	requestTypeName = ''
	responsibleName = ''
	selectedFilter = ''
	showLines: boolean
	requestTypeExportId = 'fd094c03-0793-4333-3149-08d83a029f26'
	emptyLineId = '00000000-0000-0000-0000-000000000000'
	privateKey: string = `-----BEGIN RSA PRIVATE KEY-----
	MIIEpAIBAAKCAQEAskgPKBcNpz71mi4NSYa5mazJrO0WZim7T2yy7qPxk2NqQE7O
	mWWakLJcaeUYnI0kO3yC57vck66RPCjKxWuWSGZ7dHXe0bWb5IXjcT4mNdnUIalR
	+lV8czsoH/wDUvkQdG1SJ+IxzW64WvoaCRZ+/4wBF2cSUh9oLwGEXiodUJ9oJXFZ
	VPKGCEjPcBI0vC2ADBRmVQ1sKsZg8zbHN+guU9rPLFzN4YNrCnEsSezVw/W1FKVS
	8J/Xx4HSSg7AyVwniz8eHi0e3a8VzFg+H09I5wK+w39sjDYfAdnJUkr6PjtSbN4/
	Sg/NMkKB2Ngn8oj7LCfe/7RNqIdiS+dQuSFgeQIDAQABAoIBAQCtAyEyVqz5UTAn
	n+llIUmcyQIuvyatnnIYcfRzgsY9soy6O8BjPSbypSTp31ozEwXGdN7U5Ir7Br4L
	E8s0K2R8fnfwXTMPcJ8I9qXyj82lFO+4ewkDV1KewRn/cCIJs4s0caL48ZpDMt/V
	6kC58QruFIA6pdwxtij09RPwDg7E3U1MrL9PqgGXXQcAwtboP7gHs2ac+0b6CwV0
	Cyev09Xpni3ID0iKX8yKsxi6wOh+2O4Mz8B3R2gIXq7Gzw8oeho0nSBD19B7fWs+
	w4vFlHIl57zbeISQK10cvvJ8BB4byy/bn5V2O2lESgU10d48csD5IUcjdV4/yuFx
	vI8S9vfBAoGBAOqbw03WXnhZQu8O7Gos8P7lrBi3eYdF45hHAfWbK5ub6O7fC0kQ
	FYZqO0AR7oEAzuApWP0uGxQFALZ0QPyFZbpnKinE9T9a4M5AaP1Ex8WmeWbto96b
	md6jVRXKXz24x5GDk+UdF7PJF91DufpUB5ZaO/AXpB9/3R9BDu7JHTjzAoGBAMKJ
	grfZmyH3B9Cslnb8HUSlbXZQ/tnLoc3DpXnaENpYKWz/FMjqxTHuVRRqH86tYL33
	/ow3UCaN3EYgIguoVC+DTbzk3/I9IXXeDXrocH1qCUy+x6dohzPoKZMoILLs+VeF
	eAJfIptgVIfcD5teKdEY4lYPbV0m47Q2ET8pgNvjAoGADWgDPBJw6Y2oPoLqzZJt
	3xL+x5bMcgdzXwNHwGIylrzNDcFfIrixkjHF2v5rU6HZMIQMWsNktrsmdEamZAe3
	bXRxu1tO7GTkDcUuerKQe+HsdA5GW/UmUMuilBExahSCCvWq4N/Nt+9Y1sMFAKIG
	oPfdDvY64bXry9pq4QhLDDMCgYAGs9ovH24641n/lJKyksknXlPBKdU+B8gRYNZI
	tnyyKSt0N+2tZnlY2Q9RXr6tzkdxBCRfDGDUkzqtp9nEcQ5FfM6m3XPh0r1eZEIu
	o+eMSqT7Ye9bEzzlSvBzw4Qs5gFJRK331vNISy9eZvSas8nZDHRv5qQr+3UI9nvt
	oy5vZQKBgQCK5z2IeL1NFd5OuJaR07Y+xwO7IOiZ+nn+fXWYkZ4bD08Dg0ACnd/w
	OcS+2fU2/IIuXnhT/f5M5r0AJI1lG/V8TBo4gxN2dm7yBLgczbK3u+tex+pEnfEZ
	qxAE0159q3iGvU5nmtX6VC0Z/IJIUCVgiEMwoKct0T5eOV2iQf5PaQ==
	-----END RSA PRIVATE KEY-----`

	constructor(
		private formsBuilder: FormBuilder,
		private router: Router,
		private responsibleService: ResponsiblesService,
		private alertService: AlertService,
		private spinnerService: NgxSpinnerService
	) {}

	ngOnInit() {
		this.responsibleFormGroup = this.formsBuilder.group({
			responsibleId: ['', Validators.required],
			requestTypeId: ['', Validators.required],
			lineId: ['00000000-0000-0000-0000-000000000000'],
		})

		this.getResponsibles()
	}

	getResponsibles() {
		this.spinnerService.show()
		this.responsibleService
			.getResponsibles()
			.subscribe(
				(list) => {
					list.forEach((element) => {
						var rsa = forge.pki.privateKeyFromPem(this.privateKey)
						var idByte = forge.util.decode64(element.id)
						var id = rsa.decrypt(idByte)
						element.id = id
          });
          (this.responsible = list), this.spinnerService.hide()
				},
				(error) => {
					this.spinnerService.hide()
					switch (error.status) {
						case 401:
							return this.alertService
								.error('No tiene los permisos para consultar este recurso')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
						case 403:
							return this.alertService
								.error('No tiene los permisos para consultar este recurso')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
						case 500:
							return this.alertService
								.error('Oop! Ha ocurrido un error')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
						case 0:
							return this.alertService
								.error('Oops! Ha ocurrido un error.', 'Error')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
						case undefined:
							return this.alertService
								.error('Oops! Ha ocurrido un error.', 'Error')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
					}
				}
			)
			.add(this.getRequestType())
	}

	getRequestType() {
		this.responsibleService.getRequestType().subscribe(
			(list) => (this.requestType = list),
			(error) => {
				switch (error.status) {
					case 401:
						return this.alertService
							.error('No tiene los permisos para consultar este recurso')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case 403:
						return this.alertService
							.error('No tiene los permisos para consultar este recurso')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case 500:
						return this.alertService
							.error('Oop! Ha ocurrido un error')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case 0:
						return this.alertService
							.error('Oops! Ha ocurrido un error.', 'Error')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case undefined:
						return this.alertService
							.error('Oops! Ha ocurrido un error.', 'Error')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
				}
			}
		)
	}

	getLines(requestTypeId: string) {
		this.responsibleService.getLinesByRequestTypeId(requestTypeId).subscribe(
			(list) => {
				;(this.lines = list), (this.showLines = true)
			},
			(error) => {
				;(this.lines = null), (this.showLines = false)
			}
		)
	}

	onSubmit() {
		if (this.responsibleFormGroup.invalid) {
			return
		}
		this.assignRequestType()
	}

	assignRequestType() {
		this.responsibleRequestType = this.responsibleFormGroup
			.value as AssingResponsibleRequestType

		if (
			this.responsibleRequestType.requestTypeId == this.requestTypeExportId &&
			this.responsibleRequestType.lineId == this.emptyLineId
		) {
			this.alertService.warning('Debe seleccionar una línea')
			return
		} else if (
			this.responsibleRequestType.requestTypeId != this.requestTypeExportId &&
			this.responsibleRequestType.lineId != this.emptyLineId
		) {
			this.responsibleRequestType.lineId = this.emptyLineId
		}

		this.responsibleService
			.assignRequestTypeToResponsible(this.responsibleRequestType)
			.subscribe(
				(responsibleRequestType) => this.onSaveSuccess(),
				(error) => {
					switch (error.status) {
						case 401:
							return this.alertService
								.error('No posee los permisos para realizar esta acción')
								.then((result) => {
									if (result.value) {
										this.router.navigate([
											'/mardomgoadmin/responsible-request-type',
										])
									}
								})
						case 403:
							return this.alertService
								.error('No posee los permisos para realizar esta acción')
								.then((result) => {
									if (result.value) {
										this.router.navigate([
											'/mardomgoadmin/responsible-request-type',
										])
									}
								})
						case 500:
							return this.alertService
								.error('Oop! Ha ocurrido un error')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
						case 0:
							return this.alertService
								.error('Oops! Ha ocurrido un error.', 'Error')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
						case undefined:
							return this.alertService
								.error('Oops! Ha ocurrido un error.', 'Error')
								.then((result) => {
									if (result.value) {
										this.router.navigate(['/mardomgoadmin/dashboard'])
									}
								})
					}
				}
			)
	}

	onSaveSuccess() {
		this.alertService
			.info('Cambios realizados satisfactoriamente')
			.then((result) => {
				if (result.value) {
					this.router.navigate(['/mardomgoadmin/responsible-request-type'])
				}
			})
	}

	onChangeRequestType() {
		this.requestTypeId = this.selectedFilter
		if (this.requestTypeId == this.requestTypeExportId) {
			this.getLines(this.requestTypeId)
		} else {
			this.lines = null
			this.showLines = false
		}
	}
}

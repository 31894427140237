export enum Action {
    AccederAPantalla= '9760A31D-F86D-44C7-8085-652311CA71C4',
    AsignacionDeCuentaACliente ='5184E8EA-B91B-4350-A170-1292633EAAC2' ,  
    AsignacionDeResponsableACliente ='9A31585F-43EC-440E-9088-90AF8C8220A9' ,  
    CreacionDeUsuarioInterno ='6582CDC8-80F8-41BD-AF47-63E43FF267DC',
    BusquedaDeEmpleadoEnEikonPorNombre ='2E027B82-F985-42EE-BB4B-1D976060CF8E',
    BusquedaDeEmpleadoEnEikonPorCodigo ='195E250F-C2B7-49C4-89C8-FCC4C99194D9',
    InactivacionCuenta ='C238FA62-4576-47ED-A004-B7379E3EEEEA',
    ActivarcionCuenta ='DAA696FC-0AD7-45B4-A4AA-BC4CB2B0F661',
    AsignacionDeRol='4D677870-5AF6-4952-B23B-164BB9A21257',
    AsignacionDePermisos='2DDD8181-F165-4143-B984-5FD844D57EBD',

}
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { AuthService } from '../../../../../core/auth/_services';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms'
import { Country } from '../../../../../core/auth/_models/Country';
import { User } from '../../../models/user'
import { MustMatch } from '../../../../../core/auth/helpers/must-match.validator';
import { IndustryType } from '../../../../../core/auth/_models/IndustryType'
import { PersonTitle } from '../../../../../core/auth/_models/personTitle'
import Swal from 'sweetalert2'
import { Router } from '@angular/router'
import { DateValidator } from '../../../../../core/auth/helpers/date-validator'
import { UseExistingWebDriver } from 'protractor/built/driverProviders'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ChangeDetectorRef} from '@angular/core';
import {LoginService} from '../../../services/login/login.service';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../../../../core/services/alert.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  @ViewChild('showSucessModal', { static: true }) showSucessModal: TemplateRef<any>;
  userFormGroup: FormGroup
  submitted = false
  confirmType = false
  password: string
  confirmPassword: string
  countries: Country[]
  indyustryTypes: IndustryType[]
  personTitles: PersonTitle[]
  errorMessage: string
  hasError: boolean
  countryName = 'name'
  selectedCountry: any = []
  selectedCountryName = "26b5c993-bf78-4216-8eb5-4e377c6665ef"
  showInputPassword: boolean
  showInputConfirmPassword: boolean
  state = false;
  public newpasswordMsg:string = 'Nivel de Seguridad';
  public checkAcceptance: boolean = false;
  public cityChange: boolean = true;
  public coutryList: any = [];
  public weakpass: boolean = false;
  public mediumpass: boolean = false;
  public strongpass: boolean = false;
  public CountryList:any=[];
  public country:string;
  public matchpassword:boolean=false;
  public sortedlist:any=[];
  public showerror:boolean=false;
  public personTitle:any=[]
  public TitleId:number;
  public listpersonal:any=[]
  public singleSelectOptions = {
    multiple: false,
    placeholder: 'Título',
  }
  public emailValidation:boolean =false
  public phoneValidation:boolean =false;
  public emailExitOrnot:boolean =false;
  public checkLengthofRNC:boolean=false;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private loginService:LoginService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
  ) {}
  private showErrorMessage(message: string) {
    Swal.fire({
      title: '',
      text: message,
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok!',
    }).then((result) => {
    })
    this.errorMessage = message
    this.hasError = true
  }

  ngOnInit() {
    this.getform()
    this.getIndustryTypes()
    this.getPersonTitle()
    this.getCountries();
    this.selectedCountry = { id: "26b5c993-bf78-4216-8eb5-4e377c6665ef", name: "Dominican Republic", code: "DO" }
    $('input[name="email"]').val('');
    $('input[name="password"]').val('');
  }

  getform(){
    this.userFormGroup = this.formBuilder.group(
      {
        mask: new FormControl(),
        name: [
          '',
          Validators.compose([Validators.required, Validators.maxLength(100)]),
        ],
        lastName: [
          '',
          Validators.compose([Validators.required, Validators.maxLength(100)]),
        ],
        // title: ['', Validators.required],
        title: [''],
        birthday: [
          '',
          Validators.compose([
            Validators.required,
            DateValidator.dateValidator,
          ]),
        ],
        phoneOffice: [''],
        cellPhone: [
          '',
          Validators.compose([Validators.required, Validators.maxLength(10)]),
        ],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.maxLength(120),
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
        ],
        password: ['', Validators.compose([Validators.required])],
        confirmPassword: ['', Validators.compose([Validators.required])],
        rnc: ['',Validators.compose([Validators.required, Validators.maxLength(20)]),],
        companyName: ['', Validators.compose([Validators.required, Validators.maxLength(100)]),],
        industryTypeId: [''],
        webPage: '',
        address: '',
        city: [''],
        countryId: ['Dominican Republic', Validators.required],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    )
  }

  checkConfirmPwdValidation() {
    let cpassBox = document.getElementById('cpasswordbox')
    if (this.password !== this.confirmPassword && this.confirmPassword && this.confirmPassword.length >= 1) {
      this.matchpassword=true;
      cpassBox.classList.add('cpass');
    } else if (this.password === this.confirmPassword) {
      this.matchpassword=false;
      cpassBox.classList.remove('cpass');
    }
  }


  getIndustryTypes() {
    this.loginService.getIndustryTypes().subscribe(
      (list) => (this.indyustryTypes = list),
      (error) => console.log(error)
    )
  }

  getPersonTitle() {
    this.loginService.getPersonTitle().subscribe((data:any) => {
      const list = data.map((title) => {
        return { id: title.id, text: title.description, ...title }
      });
      this.listpersonal = list;
    },
      (error) => console.log(error)
    )
  }

  selecttitle(event){
   this.userFormGroup.controls.title.setValue(event.value)
  }

  getCountries() {
     this.loginService.getCountries().subscribe(response => {
      this.coutryList = response;
      const list =response.map((country) => {
        return { id: country.id, text: country.name, ...country }
      });
      this.CountryList=list;
     /*  this.CountryList.splice(0, 0, {
        id: '26b5c993-bf78-4216-8eb5-4e377c6665ef',
        text: 'Dominican Republic'
      }); */
      this.sortByProp();
      this.country ='Dominican Republic'
      this.cd.detectChanges();
    }) 
  }

  
  hideErrorMessage() {
    this.hasError = false;
  }

  onSubmit() {
    this.submitted = true
    if (this.userFormGroup.invalid || !this.checkAcceptance || this.checkLengthofRNC==true) {
      Swal.fire({
        title: '',
        text: this.userFormGroup.invalid?'Error en solicitud: Favor de llenar todos los campos obligatorios':'Por favor acepte los Términos y Condiciones y las Políticas de Privacidad',
     // text: this.userFormGroup.invalid?'Error en solicitud: Favor de llenar todos los campos obligatorios':(!this.checkAcceptance?'Por favor acepte los Términos y Condiciones y las Políticas de Privacidad':(this.checkLengthofRNC === true?'RNC no válida':'')),
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
      }).then((result) => {
        return
      })
    }else {
      const user = this.userFormGroup.value as User
      if (this.selectedCountry.length == 0) {
        this.showErrorMessage('Debe seleccionar un país')
      } else {
         this.singUp(user)
      }
    }
  }

  private async singUp(user: User) {
    try {
      const response = await this.authService
        .singUp({
          name: `${user.name} ${user.lastName}`,
          email: user.email,
          password: user.password,
          given_name: user.name,
          family_name: user.lastName,
          nickname: `${user.title} ${user.lastName}`,
          userMetadata: {
            personal_preferences: JSON.stringify({
              rank_title: user.title,
              birth_date: moment(user.birthday),
            }),
            contact_information: JSON.stringify({
              office_phone: user.phoneOffice,
              cellphone: user.cellPhone,
              city_code: user.city,
              country_code: this.selectedCountryName,
            }),
            company_information: JSON.stringify({
              companyName: this.userFormGroup.controls.companyName.value,
              rank_title: user.title,
              office_phone: user.phoneOffice,
              cellphone: user.cellPhone,
              city_name: user.city,
              country_code: this.selectedCountryName,
            }),

          },
        })
        .toPromise()
      this.modalService.open(this.showSucessModal, {
        size: 'md', backdrop: 'static',
        keyboard: false
      });
      this.sendSuccessfullRegistrationEmail()
    } catch (error) {
      console.log(error.error.message)
      let errorMessage = error.error.message
        ? error.error.message
        : error.error.description

      switch (errorMessage) {
        case 'Password is too weak': {
          errorMessage = 'La contraseña es débil'
          break
        }
        case 'Invalid sign up': {
          errorMessage = 'Registro inválido. Revise la información suministrada'
          break
        }
        case 'Extensibility error': {
          errorMessage =
            'Registro inválido. Revise la información suministrada.'
          break
        }
        case 'The user already exists.': {
          errorMessage = 'La cuenta de correo ya existe en la Plataforma'
          break
        }
      }
      this.showErrorMessage(errorMessage)
    }
  }


  sendSuccessfullRegistrationEmail(){
    const user = this.userFormGroup.value as User
    let payload ={
      name:`${user.name} ${user.lastName}`,
      email:'insidesales@mardom.com',
      emailAccount:user.email,
      companyName:this.userFormGroup.controls.companyName.value,
    }
    this.loginService.sendSuccessfullRegistrationEmail(payload,).subscribe(res => {
    }, (error) => {
      
    }
    )
  }

  showPassword() {
    this.showInputPassword = !this.showInputPassword
  }
  showConfirmPassword() {
    this.showInputConfirmPassword = !this.showInputConfirmPassword
  }

  selectEvent(item) {
    this.selectedCountry = item
    this.country=item.name;
    this.userFormGroup.controls.companyName.enable();
    this.userFormGroup.controls.companyName.setValue('')
    this.userFormGroup.controls.rnc.setValue('')
    if (item.name == 'Dominican Republic') {
      this.selectedCountryName= '26b5c993-bf78-4216-8eb5-4e377c6665ef'
      this.userFormGroup.controls.countryId.setValue(item.name);
      this.cityChange = true
    } else {
      const li=this.coutryList.find(x=>x.name==item.name)
      this.selectedCountryName=li.id;
      this.userFormGroup.controls.countryId.setValue(item.name);
      this.cityChange = false
      this.userFormGroup.controls.companyName.setValue('')
      this.userFormGroup.controls.rnc.setValue('')
    }
    
  }

  onChangeSearch(val: string) {
    this.selectedCountry = this.countries.filter((x) => {
      return x.name.toLocaleLowerCase().includes(val.toLocaleLowerCase())
    })

  }

  public async rncVerifiy(event) {
    if (this.country == 'Dominican Republic') {
      this.checkLengthofRNC=true;
      let rnc = event.target.value.replace(/[^\d]/g, "").toString();
      //var newStr = rnc.replace(/-/g, "");
      if (rnc && rnc.toString().length >= 9) {
        this.checkLengthofRNC=false;
        this.spinnerService.show()
      await  this.loginService.verifyRNC(rnc).subscribe((response: any) => {
          this.userFormGroup.controls.companyName.setValue(response.data.name);
          //this.userFormGroup.controls.rnc.setValue(response.data.rnc);
          this.userFormGroup.controls.companyName.disable();
          this.spinnerService.hide();
          this.cd.detectChanges()
        }, (error) => {
          if(error.status != 504){
            this.spinnerService.hide();
            this.userFormGroup.controls.companyName.setValue('');
            this.showErrorMessage('Validar RNC, no existe en base de datos de DGII. Si cree que esta recibiendo este mensaje por error, por favor comunicarse con helpdeskmardomgo@mardom.com')
          }
        })
      } else {
        this.spinnerService.hide();
        this.userFormGroup.controls.companyName.setValue('')
      }
    }
  }

  checkCheckbx(event) {
    if (event.target.checked) {
      this.checkAcceptance = true
    } else {
      this.checkAcceptance = false
    }
  }

  goToLogin() {
    this.modalService.dismissAll();
    this.router.navigateByUrl('/login', {
      skipLocationChange: true,
    })
  }

  sendEmail() {
    this.loginService.sendEmail(this.userFormGroup.controls.email.value).subscribe(res => {
      this.modalService.dismissAll();
      this.router.navigateByUrl('/login', {
        skipLocationChange: true,
      })
      this.cd.detectChanges();
      this.alertService.info('Se reenvió exitosamente el correo de confirmación')
    }, (error) => {
      this.showerror=true;
    }
    )
  }

  checkValidation(type){
    if(type == 'email'){
      if(this.userFormGroup.controls.email.value && this.userFormGroup.controls.email.invalid){
        this.emailValidation = true
      }else{
        this.emailValidation = false;
        this.checkEmailExit()
      }
    }else if(type == 'phone'){
      if(this.userFormGroup.controls.cellPhone.value && this.userFormGroup.controls.cellPhone.invalid){
        this.phoneValidation = true
      }else{
        this.phoneValidation = false
      }
    }
  }

  removeValidation(type) {
    if (type == 'email') {
      this.emailExitOrnot= false
      this.emailValidation = false
    }else if(type == 'phone'){
      this.phoneValidation = false
    }
  }

  checkEmailExit(){
    this.loginService.checkEmailExit(this.userFormGroup.controls.email.value).subscribe((res:any) => {
     this.emailExitOrnot = res=='True'?true:false;
     this.emailValidation = this.emailExitOrnot;
     this.cd.detectChanges();
    }, (error) => {
      
    }
    )
  }

  //********** */
  check(){
    this.newpasswordMsg = 'Nivel de Seguridad';
    var str = this.userFormGroup.controls.password.value;
    var sL = str.length;
      if (sL > 4) {
        this.weak()
        this.newpasswordMsg = 'Seguridad debil';
      }
      if ((str.match(/[A-Z]/) && str.match(/[a-z]/) && sL >= 8) || sL >= 8) {
        this.medium()
        this.newpasswordMsg = 'Seguridad Media';
      }
      if (str.match(/[A-Z]/) && str.match(/[a-z]/) && str.match(/[0-9]/) && sL > 8) {
        this.strong()
        this.newpasswordMsg = 'Seguridad Fuerte';
      }
      if(sL <= 4 ){
        this.normal()
      }
  }

  normal(){
    this.weakpass = false
    this.mediumpass = false
    this.strongpass = false
  }
  weak(){
    this.weakpass = true
    this.mediumpass = false
    this.strongpass = false
  }
  medium(){
    this.weakpass = false
    this.mediumpass = true
    this.strongpass = false
  }
  strong(){
    this.weakpass = false
    this.mediumpass = false
    this.strongpass = true
  }

    goToLink(url){
      window.open(url, "_blank");
    }

    getSortOrder1() {
      let prop ='name';
      return function (a, b) {
        if (a[prop] > b[prop]) {
          return 1;
        }
        else if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      }
    }
  
   sortByProp() {
      this.sortedlist= this.coutryList.sort(this.getSortOrder1());
    }
}

import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HorizontalStepperComponent } from '../../customer/horizontal-stepper/horizontal-stepper.component'
import { config } from '../../../../../../environments/environment'
import {PerfilEmpresaService} from '../../../services/perfil-empresa.service'
import { NgxSpinnerService } from 'ngx-spinner'
import { AlertService } from '../../../../../core/services/alert.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import * as jwt_decode from "jwt-decode";
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DatashareService } from '../../../services/datashare.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'kt-perfil-empresa',
  templateUrl: './perfil-empresa.component.html',
  styleUrls: ['./perfil-empresa.component.scss']
})
export class PerfilEmpresaComponent implements OnInit {
  @ViewChild('updateSuccess', { static: true }) updateSuccess: TemplateRef<any>
  @ViewChild('toggleModel', { static: true }) toggleModel: TemplateRef<any>;
    public CustomerServiceCoordinator:any=[];
    public employeeAccount:any=[];
    public accountExecutiveId: string = "#";
    public responsibleRequestTypeId: string = "#";
    public UserList:any=[];
    public loading:Boolean;
    public emptyList:Boolean;
    public status:Boolean;
    public currentIndex:any;
    public StatusInfo:any={};
    public submitted:Boolean=false;
    public companyDetails:any;
    public companyForm: FormGroup;
    CompanyInfo:any;
    customerId='';
    modalConfig= {
    size: 'xl',
    backdrop: 'static',
    keyboard: false,
    } as NgbModalOptions
  constructor(    
    private modalService: NgbModal,
    private perfilEmpresaService:PerfilEmpresaService,
    private spinnerServices: NgxSpinnerService,
    private alertService: AlertService,
    private appState: Store<AppState>,
    private datashareService:DatashareService,
    private router: Router,
    private formBuilder: FormBuilder,
    private chRef: ChangeDetectorRef,
    ) {}

  ngOnInit() {
   
    //this.getUserAllCompany();
    this.generateDriverForm();
    this.getcompanyDetails();
   
  }

  generateDriverForm() {
    this.companyForm = this.formBuilder.group({
      Name: ['', [Validators.required]],
      country: ['', [Validators.required]],
      rnc:['', [Validators.required]],
      email: ['', [Validators.required]],
      Telephone:['', [Validators.required]],
    })
  }

  patchvalue(){
    this.companyForm.controls.Name.setValue(this.CompanyInfo.name);
    this.companyForm.controls.rnc.setValue(this.CompanyInfo.rnc);
    /*this.companyForm.controls.country.setValue(this.CompanyInfo.country);
    this.companyForm.controls.email.setValue(this.CompanyInfo.email);
    this.companyForm.controls.Telephone.setValue(this.CompanyInfo.Telephone);*/
  }

  getcompanyDetails(){
    this.companyDetails=this.datashareService.getrncnumber();
    if(!this.companyDetails){
      return this.router.navigate(["/mardomgoadmin/allCompaniesList"])
    }else{
    this.getCompanyInfo()
    this.getCustomerServiceCoordinator();
    this.getemployeeAccount();
    this.getUserList();
    this.gettokenData();
    }
  }
  getCompanyInfo(){
    this.spinnerServices.show();
    this.perfilEmpresaService.getCompanyInfo(this.companyDetails.rnc).subscribe((response)=>{
    this.CompanyInfo=response[0]
    this.spinnerServices.hide();
      this.patchvalue();
    },(error) => {
      this.spinnerServices.hide();
    })
  }

  gettokenData(){
    let token = '';
    this.appState.select(selectAuthState).subscribe(
        result => { 
            token = result.authToken;
        })
    const currentData = jwt_decode(token);
    this.customerId=currentData['https://mardom.com/clientId']
  }

  associateCustomer(item: any): void {
		let modal = this.modalService.open( 
			HorizontalStepperComponent,
			//config.modalConfig
      this.modalConfig
      
		)		
		// modal.componentInstance.customerId = item.clientId
		// modal.componentInstance.customerName = item.asociatedCompanyName
		// modal.componentInstance.customer = item
	}

  getUserAllCompany(){
    this.spinnerServices.show();
    this.perfilEmpresaService.getAllUserCompany().subscribe((response)=>{
      this.spinnerServices.hide();
    },(error) => {
      this.spinnerServices.hide();
    });
  }

  getCustomerServiceCoordinator(){
    this.spinnerServices.show();
    this.perfilEmpresaService.CustomerServiceCoordinator().subscribe((response)=>{
      this.CustomerServiceCoordinator=response;
      this.spinnerServices.hide();
    },(error) => {
      this.spinnerServices.hide();
    })
  }
  getemployeeAccount(){
    this.spinnerServices.show();
    this.perfilEmpresaService.getemployeeAccount().subscribe((response)=>{
      this.employeeAccount=response;
      this.spinnerServices.hide();
    },(error) => {
      this.spinnerServices.hide();
    })
  }

  assignResponsibleToCustomer(){
    this.spinnerServices.show();
    let assingResponsibleCustomerDto = []
    let data ={
      customerId:this.customerId,
      active:true,
      mainResponsible:false,
      responsibleRequestTypeId:this.responsibleRequestTypeId
    }
    assingResponsibleCustomerDto.push(data)
    let responsible={assingResponsibleCustomerDto:assingResponsibleCustomerDto}
    
      // let responsible={
      // "customerId": this.customerId,
      // "active": true,
      // "mainResponsible": false,
      // "responsibleRequestTypeId": this.responsibleRequestTypeId }
     

    this.perfilEmpresaService.assignResponsibleToCustomer(responsible).subscribe((response)=>{
      this.spinnerServices.hide();
      this.alertService.success('Exito!', 'success');
    },(error) => {
      this.spinnerServices.hide();
      this.alertService.error(error.error.errors[0].message, 'Error')
    })
  }

  assignExceutiveToCustomer(){
    this.spinnerServices.show()
    let customer={
      "customerId": this.customerId,
      "active": true,
      "executiveid": this.accountExecutiveId}
    this.perfilEmpresaService.assignExceutiveToCustomer(customer).subscribe((response)=>{
      this.spinnerServices.hide();
      this.alertService.success('Exito!', 'success');
    },(error) => {
      this.spinnerServices.hide();
      //this.alertService.error('Oops! Ha ocurrido un error.', 'Error')
    })
  }

  saveResponsible(){
    if(this.accountExecutiveId=='#' || this.responsibleRequestTypeId=='#')
    {this.submitted==true;
      this.alertService.info('Por favor seleccione Ejecutivo de Cuenta y Coordinador de Servicio al Cliente','Info')
    }else{
      this.assignExceutiveToCustomer();
      this.assignResponsibleToCustomer();
    }
  }

  getUserList(){
    this.spinnerServices.show();
    this.loading=true
    // this.perfilEmpresaService.getUserList().subscribe((response)=>{
    //   for (let i = 0; i < response.length; i++) {
    //     if (response[i].userProfile == null) {
    //     } else {
    //       this.UserList.push(response[i])
    //     }
    //   }
    //this.emptyList=response.length==0?false:true;
    //   this.loading=false;
    // },(error) => {
    // this.loading=false
    //   this.spinnerServices.hide();
    // })

    this.perfilEmpresaService.getUserWithcompanyId(this.companyDetails.id).subscribe((response)=>{
      for (let i = 0; i < response.length; i++) {
        if (response[i].userProfile == null) {
        } else {
          this.UserList.push(response[i])
        }
      }
      this.emptyList=(response.length==0 || response[0].userProfile==null )?false:true;
      this.loading=false;
      this.chRef.detectChanges();
    },(error) => {
      this.spinnerServices.hide();
      this.loading=false;
    })
  }

  statuschange(item, e, index) {
    this.currentIndex = index
    this.status = e.target.checked == true ? true : false;
    this.StatusInfo = {
      "userid": item.userProfile.UserId,
      "companyid": this.CompanyInfo.id,
      "specialrole": item.roles[0],
      "role": "",
      "status": e.target.checked == true ? 'active' : 'inactive'
    }
   this. openModel()
  }

  openModel() {
    this.modalService.open(this.toggleModel, {
      size: 'sm', backdrop: 'static'
    });
  }
  
  closeToggleModal(){
    this.modalService.dismissAll();
    let togBtn = document.getElementById("togBtn-" + this.currentIndex) as HTMLInputElement
    togBtn.checked = this.status == true ? false : true
  }

  SaveStatus(){
    this.perfilEmpresaService.assignCompanyToUser(this.StatusInfo).subscribe((response)=>{
      this.modalService.dismissAll();
    },(error) => {
      this.closeToggleModal();
      this.spinnerServices.hide();
      this.alertService.error('Oops! Ha ocurrido un error.', 'Error')
    })
  }

  
 
}

import { Component, OnInit, ViewChild } from '@angular/core'
import { EmployeesaccountService } from '../../../services/employeesaccount.service'
import { internaluser } from '../../../models/internal-users/internaluser'
import { AlertService } from '../../../../../../app/core/services/alert.service'
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material'
import { AddRoleToUserComponent } from '../../add-role-to-user/add-role-to-user.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { config } from '../../../../../../../src/environments/environment'
import { Router } from '@angular/router'
import Swal from 'sweetalert2'
import { NgxSpinnerService } from 'ngx-spinner'
import { UserAccountService } from '../../../services/user-account.service'
import * as forge from 'node-forge'
import { Log } from '../../../models/log'
import { Action } from '../../../models/enum/action'
import { LogService } from '../../../services/log.service'
import { Screen } from '../../../models/enum/screen'

@Component({
	selector: 'kt-internal-users',
	templateUrl: './internal-users.component.html',
	styleUrls: ['./internal-users.component.scss'],
})
export class InternalUsersComponent implements OnInit {
	config: any
	get internalUsers() {
		return this.internalUser
	}
	privateKey: string = `-----BEGIN RSA PRIVATE KEY-----
	MIIEpAIBAAKCAQEAskgPKBcNpz71mi4NSYa5mazJrO0WZim7T2yy7qPxk2NqQE7O
	mWWakLJcaeUYnI0kO3yC57vck66RPCjKxWuWSGZ7dHXe0bWb5IXjcT4mNdnUIalR
	+lV8czsoH/wDUvkQdG1SJ+IxzW64WvoaCRZ+/4wBF2cSUh9oLwGEXiodUJ9oJXFZ
	VPKGCEjPcBI0vC2ADBRmVQ1sKsZg8zbHN+guU9rPLFzN4YNrCnEsSezVw/W1FKVS
	8J/Xx4HSSg7AyVwniz8eHi0e3a8VzFg+H09I5wK+w39sjDYfAdnJUkr6PjtSbN4/
	Sg/NMkKB2Ngn8oj7LCfe/7RNqIdiS+dQuSFgeQIDAQABAoIBAQCtAyEyVqz5UTAn
	n+llIUmcyQIuvyatnnIYcfRzgsY9soy6O8BjPSbypSTp31ozEwXGdN7U5Ir7Br4L
	E8s0K2R8fnfwXTMPcJ8I9qXyj82lFO+4ewkDV1KewRn/cCIJs4s0caL48ZpDMt/V
	6kC58QruFIA6pdwxtij09RPwDg7E3U1MrL9PqgGXXQcAwtboP7gHs2ac+0b6CwV0
	Cyev09Xpni3ID0iKX8yKsxi6wOh+2O4Mz8B3R2gIXq7Gzw8oeho0nSBD19B7fWs+
	w4vFlHIl57zbeISQK10cvvJ8BB4byy/bn5V2O2lESgU10d48csD5IUcjdV4/yuFx
	vI8S9vfBAoGBAOqbw03WXnhZQu8O7Gos8P7lrBi3eYdF45hHAfWbK5ub6O7fC0kQ
	FYZqO0AR7oEAzuApWP0uGxQFALZ0QPyFZbpnKinE9T9a4M5AaP1Ex8WmeWbto96b
	md6jVRXKXz24x5GDk+UdF7PJF91DufpUB5ZaO/AXpB9/3R9BDu7JHTjzAoGBAMKJ
	grfZmyH3B9Cslnb8HUSlbXZQ/tnLoc3DpXnaENpYKWz/FMjqxTHuVRRqH86tYL33
	/ow3UCaN3EYgIguoVC+DTbzk3/I9IXXeDXrocH1qCUy+x6dohzPoKZMoILLs+VeF
	eAJfIptgVIfcD5teKdEY4lYPbV0m47Q2ET8pgNvjAoGADWgDPBJw6Y2oPoLqzZJt
	3xL+x5bMcgdzXwNHwGIylrzNDcFfIrixkjHF2v5rU6HZMIQMWsNktrsmdEamZAe3
	bXRxu1tO7GTkDcUuerKQe+HsdA5GW/UmUMuilBExahSCCvWq4N/Nt+9Y1sMFAKIG
	oPfdDvY64bXry9pq4QhLDDMCgYAGs9ovH24641n/lJKyksknXlPBKdU+B8gRYNZI
	tnyyKSt0N+2tZnlY2Q9RXr6tzkdxBCRfDGDUkzqtp9nEcQ5FfM6m3XPh0r1eZEIu
	o+eMSqT7Ye9bEzzlSvBzw4Qs5gFJRK331vNISy9eZvSas8nZDHRv5qQr+3UI9nvt
	oy5vZQKBgQCK5z2IeL1NFd5OuJaR07Y+xwO7IOiZ+nn+fXWYkZ4bD08Dg0ACnd/w
	OcS+2fU2/IIuXnhT/f5M5r0AJI1lG/V8TBo4gxN2dm7yBLgczbK3u+tex+pEnfEZ
	qxAE0159q3iGvU5nmtX6VC0Z/IJIUCVgiEMwoKct0T5eOV2iQf5PaQ==
	-----END RSA PRIVATE KEY-----`
	internalUser: internaluser[] = new Array<internaluser>()
	name: string
	hasAccessToAssignRoles: boolean = false
	all: boolean = true
	listFilters = []
	filterValue = ''
	isAdmin = 'admin'
	selectedFilter = ''
	displayedColumns = [
		'code',
		'fullName',
		'email',
		'workPosition',
		'department',
		'status',
		'options',
	]
	dataSource: MatTableDataSource<internaluser>

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
	@ViewChild(MatSort, { static: true }) sort: MatSort
	constructor(
		private employeesAccountService: EmployeesaccountService,
		private alertService: AlertService,
		private modelService: NgbModal,
		private router: Router,
		private userAccountService: UserAccountService,
		private spinnerService: NgxSpinnerService,
		private logService: LogService
	) {
		this.config = config
	}

	ngOnInit() {
		this.logService.post(<Log>{
			ScreenId: Screen.EjecutivoInterno,
			ActionId: Action.AccederAPantalla,
			Json: '',
		})
		this.employeesAccount()
		this.validateRole()
		this.getFilterCriteria()
	}

	searchByCriteria() {
		this.dataSource.filterPredicate = (data: internaluser, filter: string) => {
			return this.selectedFilter == 'email'
				? data.email.indexOf(filter) != -1
				: data.fullName.toLowerCase().indexOf(filter.toLocaleLowerCase()) != -1
		}
		this.dataSource.filter = this.filterValue
	}

	applyFilter(filter: any) {
		filter = filter.trim()
		filter = filter.toLowerCase()
		this.dataSource.filter = filter
		if (this.dataSource.filteredData.length == 0) {
			this.alertService.info('Usuario no encontrado')
		}
	}

	employeesAccount() {
		this.spinnerService.show()
		this.employeesAccountService.getEmployeeAccounts().subscribe(
			(response) => {
				response.forEach((element) => {
					var rsa = forge.pki.privateKeyFromPem(this.privateKey)
					var codeByte = forge.util.decode64(element.code)
					var code = rsa.decrypt(codeByte)
					var emailByte = forge.util.decode64(element.email)
					var email = rsa.decrypt(emailByte)
					var idByte = forge.util.decode64(element.id)
					var id = rsa.decrypt(idByte)
					var adminByte = forge.util.decode64(element.adminEmployee)
					var admin = rsa.decrypt(adminByte)
					var workPositionByte = forge.util.decode64(element.workPosition)
					var workPosition = rsa.decrypt(workPositionByte)
					element.workPosition = workPosition
					element.adminEmployee = admin
					element.id = id
					element.code = code
					element.email = email
				})
				this.internalUser = response
				this.dataSource = new MatTableDataSource(this.internalUser)
				this.dataSource.paginator = this.paginator
				this.dataSource.sort = this.sort
				this.spinnerService.hide()
			},
			(error) => {
				this.spinnerService.hide()
				switch (error.status) {
					case 401:
						return this.alertService
							.info(
								'No tiene los permisos para consultar este recurso.',
								'Aviso'
							)
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case 500:
						return this.alertService
							.error('Oops! Ha ocurrido un error.', 'Error')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case 403:
						return this.alertService
							.info(
								'No tiene los permisos para consultar este recurso.',
								'Aviso'
							)
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case 0:
						return this.alertService
							.error('Oops! Ha ocurrido un error.', 'Error')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
					case undefined:
						return this.alertService
							.error('Oops! Ha ocurrido un error.', 'Error')
							.then((result) => {
								if (result.value) {
									this.router.navigate(['/mardomgoadmin/dashboard'])
								}
							})
				}
				this.alertService.error(error.error.errors[0].message)
			}
		)
	}
	manageEmployeeAccount(id: string, name: string, state: boolean) {
		Swal.fire({
			title: '¿Deseas realizar esta acción?',
			text: `¿Está seguro que desea ${
				state ? 'Activar' : 'Inactivar'
			} a ${name}?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, Confirmado!',
		}).then((result) => {
			if (result.value) {
				this.spinnerService.show()
				this.employeesAccountService
					.employeeAccountManagement(id, state)
					.subscribe(
						(response) => {
							this.spinnerService.hide()
							this.logService.post(<Log>{
								ScreenId: Screen.EjecutivoInterno,
								ActionId: state
									? Action.ActivarcionCuenta
									: Action.InactivacionCuenta,
								Json: '',
							})
							this.alertService
								.success('Acción realizada con Exito!', 'success')
								.then((result) => {
									if (result.value) {
										this.employeesAccount()
									}
								})
						},
						(error) => {
							this.spinnerService.hide()
							switch (error.status) {
								case 401:
									return this.alertService
										.info(
											'No tiene los permisos para realizar esta acción.',
											'Aviso'
										)
										.then((result) => {
											if (result.value) {
												this.router.navigate(['/mardomgoadmin/dashboard'])
											}
										})
								case 500:
									return this.alertService
										.error('Oops! Ha ocurrido un error.', 'Error')
										.then((result) => {
											if (result.value) {
												this.router.navigate(['/mardomgoadmin/dashboard'])
											}
										})
								case 403:
									return this.alertService
										.info(
											'No tiene los permisos para realizar esta acción..',
											'Aviso'
										)
										.then((result) => {
											if (result.value) {
												this.router.navigate(['/mardomgoadmin/dashboard'])
											}
										})
								case 0:
									return this.alertService
										.error('Oops! Ha ocurrido un error.', 'Error')
										.then((result) => {
											if (result.value) {
												this.router.navigate(['/mardomgoadmin/dashboard'])
											}
										})
								case undefined:
									return this.alertService
										.error(
											'Oops! Ha ocurrido un error realizacion esta acción',
											'Error'
										)
										.then((result) => {
											if (result.value) {
												this.router.navigate(['/mardomgoadmin/dashboard'])
											}
										})
							}
						}
					)
			}
		})
	}
	getFilterCriteria() {
		this.listFilters = [
			{ id: 'email', value: 'Email' },
			{ id: 'name', value: 'Nombre' },
		]
	}
	openRoleManagement(userId: string) {
		let modal = this.modelService.open(
			AddRoleToUserComponent,
			this.config.modalConfig
		)
		modal.componentInstance.UserId = userId
	}

	validateRole() {
		this.userAccountService.getCurrentUserRole().subscribe((res) => {
			if (res) {
				const data = res.filter((item) => item.name == 'admin')
				if (data.length > 0) {
					this.hasAccessToAssignRoles = true
				}
			}
		})
	}

	getByStatus() {
		return this.internalUser
	}

	clean() {
		this.filterValue = ''
		this.selectedFilter = ''
		this.dataSource = new MatTableDataSource(this.internalUser)
	}
}

import { environment } from "./src/environments/environment";

const authConfing = {
  clientId: environment.auth.clientID.indexOf('#{') > -1 ? 'JiQbu5OrjAhDS7PAwCelrWPvWe7jft36' : environment.auth.clientID,
  domain: environment.auth.domain.indexOf('#{') > -1 ? 'mardom.auth0.com' : environment.auth.domain,
  connection:environment.auth.connection.indexOf('#{') > -1 ? 'MardomGo' : environment.auth.connection,
  audience: environment.auth.audience.indexOf('#{') > -1 ? 'go.mardom.com' : environment.auth.audience,
  scope: 'openid profile email offline_access read:invoices read:invoice-reports read:stock read:agents read:bl read:quotation read:eikon-employee read:products create:entry-order read:Bl create:reception-reference create:users update:users  update :users_app_metadata create:executive read:executive read:permission create:permission-to-role create:employee-account delete:permission-to-role delete:role read:role update:role  read:permission-to-role update:permission-to-role create:role delete:role read:employee-account create:file read:file read:eikon-employees read:client read:inactive-account update:customer-account read:customer-account create:assign-role-user delete:role-user create:assign-permission-user delete:permission-user create:assing-role-user read:get-conduce-mardomgoweb read:get-po-mardomgoweb read:get-bl-mardomgoweb read:get-container-mardomgoweb read:get-os-mardomgoweb read:responsible read:resposible-request-type create:resposible-request-type update:resposible-request-type read:resposible-customer create:resposible-customer update:resposible-customer read:role-user read:request-type create:request-type update:request-type read:client read:request-type-line create:request-type-line update:request-type-line read:lines create:lines update:lines delete:responsible-line read:responsible-line update:responsible-line create:responsible-line read:api-windip read:transport-equipment read:entry-order read:reception-reference  read:responsible-request-type read:responsible-customer read:info-salesexecutive crud:trackandtrace read:screen create:audit',
  redirectUri: environment.auth.redirectUri.indexOf('#{') > -1 ? 'https://go-admin.mardom.com/' : environment.auth.redirectUri,
};
export default authConfing;

import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from '../../services/role.service'
import { config } from '../../../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../../../../../src/app/core/services/alert.service';
import { UserAccountService } from '../../services/user-account.service';

@Component({
    templateUrl: './add-permission-to-user.component.html',
    styleUrls: ['./add-permission-to-user.component.css']
})

export class AddPermissionToUserComponent implements OnInit {

    @Output() Save: EventEmitter<any> = new EventEmitter<any>();
    ImportedList: any[] = []
    ExportedList: any[] = []
    originalPermissions: any[] = [];
    config: any;
    userId: string;
    roleId: string;
    roleName: string;
    onlyPermissions: boolean = false;
    forbidenSelection: any[] = [];

    get ImportedSelected() {
        return this.ImportedList ? this.ImportedList.filter(item => item.selected).length : 0;
    }

    get ExportedSelected() {
        return this.ExportedList ? this.ExportedList.filter(item => item.selected).length : 0;
    }

    constructor(private activeModal: NgbActiveModal,
        private roleService: RoleService,
        private spinnerService: NgxSpinnerService,
        private alertService: AlertService,
        private userAccountService: UserAccountService) {
        this.config = config;
    }

    async ngOnInit() {
        this.spinnerService.show();
        if (!(this.onlyPermissions)) {
            await this.getRolePermission();
        }
        await this.getPermissions();
        await this.getUserPermission();        
    }

    getRolePermission() {
        return new Promise((resolve, reject) => {
            this.roleService.getRolePermissions(this.roleId).subscribe(
                response => {         
                    response.map(item => {
                        this.forbidenSelection.push({...item});
                        this.ExportedList.push(item);
                    });                    
                    this.spinnerService.hide();
                    resolve('');
                },
                error => {
                    console.log(error);
                    reject()
                    this.cancel();
                    this.alertService.error("Ha ocurrido un error al obtener los permisos del rol", "error");
                }
            );
        })
    }

    getRolePermissionForbiden() {
        return new Promise((resolve, reject) => {
            this.roleService.getRolePermissions(this.roleId).subscribe(
                response => {         
                    response.map(item => {
                        this.forbidenSelection.push({...item});                        
                    });                    
                    this.spinnerService.hide();
                    resolve('');
                },
                error => {
                    console.log(error);
                    reject()
                    this.cancel();
                    this.alertService.error("Ha ocurrido un error al obtener los permisos del rol", "error");
                }
            );
        })
    }

    getUserPermission() {
        return new Promise((resolve, reject) => {            
            this.userAccountService.getUserPermission(this.userId).subscribe(
                async res => {
                    this.originalPermissions = [...res];
                    if (this.onlyPermissions) {
                        await this.getRolePermissionForbiden();
                        res.map(item => {
                            const element = this.ImportedList.find(i => i.scope === item.scope);
                            this.ExportedList.push({...element});
                            let index = this.ImportedList.findIndex(ip => ip === element);
                            this.ImportedList.splice(index, 1);
                        })                        
                        resolve('');
                    }
                }, error => {
                    console.log(error);
                    reject()
                    this.cancel();
                    this.alertService.error("Ha ocurrido un error al obtener los permisos del usuario", "error");
                }
            );
        })
    }

    getPermissions() {
        return new Promise((resolve, reject) => {            
            this.roleService.getPermissions().subscribe(
                res => {
                    res.map(resItem => {
                        const result = this.ExportedList.find(responseItem => responseItem.name === resItem.scope);
                        if (result == undefined)
                            this.ImportedList.push(resItem);                        
                    }),
                    this.spinnerService.hide();
                    resolve('');
                },
                error => {
                    console.log(error);
                    reject()
                    this.cancel();
                    this.alertService.error("Ha ocurrido un error al obtener los permisos", "error");
                }
            );
        })
    }

    selectedImported(item: any) {
        let validate = this.forbidenSelection.find(element => element.name === item.name || element.name === item.scope);
        if(!(validate)){
            item.selected = !item.selected;
        }        
    }

    exportHandler() {
        const exports = this.ExportedList.filter(item => item.selected);

        exports.map(item => {
            item.selected = false;
            this.ImportedList.push(item);
            item.selected = true;
        })

        this.ExportedList = this.ExportedList.filter(item => !item.selected);
    }

    importHandler() {
        const imports = this.ImportedList.filter(item => item.selected);

        imports.map(item => {
            item.selected = false;
            this.ExportedList.push(item);
            item.selected = true;
        })

        this.ImportedList = this.ImportedList.filter(item => !item.selected);
    }

    cancel() {
        this.activeModal.close();
    }

    save() {
        let dataTransferObject = {
            originalPermissions: this.originalPermissions,
            newPermissions: this.ExportedList
        }
        const data = JSON.stringify(dataTransferObject);
        this.Save.emit(data);
    }
}
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MardomGoAdminRoutingModule } from './routing/mardom-go-admin-routing.module';
import { HomeComponent } from './components/home/home.component';
import { ThemeModule } from '../../views/theme/theme.module';
import { InternalUsersComponent } from './components/internal-users/list-internal-users/internal-users.component';
import { EmployeesaccountService } from './services/employeesaccount.service';
import { AlertService } from '../../../app/core/services/alert.service';
import { DetailsInternalUsersComponent } from './components/internal-users/details-internal-users/details-internal-users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatMenu, MatMenuModule, MatPaginatorModule, MatTableModule, MatStepperModule, MatListModule } from '@angular/material';
import { EikonEmployeesComponent } from './components/eikon-employees/eikon-employees.component';
import { RoleService } from './services/role.service';
import { UserAccountService } from '../../../app/features/mardom-go-admin/services/user-account.service';
import { AddRoleToUserComponent } from '../../../app/features/mardom-go-admin/components/add-role-to-user/add-role-to-user.component';
import { AddPermissionToUserComponent } from '../../../app/features/mardom-go-admin/components/add-permission-to-user/add-permission-to-user.component';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ResponsibleRequestTypeComponent } from './components/responsible-request-type/list/responsible-request-type.component';
import { ResponsibleRequestTypeCreateComponent } from './components/responsible-request-type/add/responsible-request-type-create.component';
import { ResponsibleRequestTypeEditComponent } from './components/responsible-request-type/edit/responsible-request-type-edit.component';
import { ResponsibleCustomerEditComponent } from './components/responsible-customer/edit/responsible-customer-edit.component';
import { ResponsibleCustomerComponent } from './components/responsible-customer/list/responsible-customer.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CustomerModule } from './components/customer/customer.module';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { MatSelectFilterModule } from 'mat-select-filter';
import { VoucherListComponent } from './components/voucher-log/voucher-list/voucher-list.component';
import { VoucherLogComponent } from './components/voucher-log/voucher-log/voucher-log.component';
import { LogService } from './services/log.service';
import { PerfilEmpresaComponent } from './components/Empresas/perfil-empresa/perfil-empresa.component';
import {AllCompaniesListComponent} from './components/Empresas/all-companies-list/all-companies-list.component'
import { LoginComponent } from './components/session/login/login.component';
import { PasswordRecoveryComponent } from './components/session/password-recovery/password-recovery.component';
import { SignupComponent } from './components/session/signup/signup.component';
import { MatDatepickerModule,MatNativeDateModule} from '@angular/material';
import { SharedModule } from './components/shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    declarations: [
        LoginComponent,
        PasswordRecoveryComponent,
        SignupComponent,
        HomeComponent,
        AddRoleToUserComponent,
        InternalUsersComponent,
        DetailsInternalUsersComponent,
        EikonEmployeesComponent,
        AddPermissionToUserComponent,
        ResponsibleRequestTypeComponent,
        ResponsibleRequestTypeCreateComponent,
        ResponsibleRequestTypeEditComponent,        
        ResponsibleCustomerEditComponent,
        ResponsibleCustomerComponent,
        PerfilEmpresaComponent,
        AllCompaniesListComponent

    ],
    entryComponents: [
        AddPermissionToUserComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        MardomGoAdminRoutingModule,
        NgbModule,
        ThemeModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatTableModule,
        MatPaginatorModule,
        MatInputModule,
        MatMenuModule,
        MatIconModule,
        MatListModule,
        NgxSpinnerModule,
        AutocompleteLibModule,
        CustomerModule,
        MglTimelineModule,
        MatSelectFilterModule,
        MatNativeDateModule,
        MatInputModule,
        MatDatepickerModule,
        SharedModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        EmployeesaccountService,
        AlertService,
        RoleService,
        AlertService,
        UserAccountService,
        NgxSpinnerService,
        LogService
    ]
})
export class MardomGoAdminModule { }
import { Injectable } from '@angular/core';
import { CorsHeaders } from '../../../core/services/corsHeader'
import { HttpClient } from '@angular/common/http'
import { endpoint } from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class PerfilEmpresaService {

  constructor(
    private _httpClient: HttpClient,
		private corsHeader: CorsHeaders
  ) {}

  getAllUserCompany() {        
    return this._httpClient.get<any>(endpoint.getAllUserCompany, {headers:this.corsHeader.headers()});
  }

  CustomerServiceCoordinator(){
    return this._httpClient.get<any>(endpoint.responsibleRequestTypeEndPoint, {headers:this.corsHeader.headers()});
  }employeeAccount

  getemployeeAccount() {        
    return this._httpClient.get<any>(endpoint.employeeAccount, {headers:this.corsHeader.headers()});
  }

  assignResponsibleToCustomer(responsibleCustomer){
    return this._httpClient.post<any>(endpoint.assignResponsibleToCustomerEndPoint, responsibleCustomer, {headers:this.corsHeader.headers()});
  }

  assignExceutiveToCustomer(Customer){
    return this._httpClient.post<any>(endpoint.assignToCustomer, Customer, {headers:this.corsHeader.headers()});
  }

  getUserList(){
    return this._httpClient.get<any>(endpoint.getUserList, {headers:this.corsHeader.headers()});
  }
  assignCompanyToUser(status){
    return this._httpClient.post<any>(endpoint.assignCompanyToUser, status, {headers:this.corsHeader.headers()});
  }
  
  getCompanyInfo(rnc){
    return this._httpClient.get<any>(endpoint.getCompanyInfo + rnc, {headers:this.corsHeader.headers()});
  }
  getUserWithcompanyId(companyId){
    return this._httpClient.get<any>(endpoint.getUserWithcompanyId + companyId, {headers:this.corsHeader.headers()});
  }



}


import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
// Components
import { BaseComponent } from './views/theme/base/base.component'
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component'
// Auth
import { AuthGuard } from './core/auth'
import { ModuleGuard as PermissionGuard } from './core/auth/_guards/permission.guard'
import { HomeComponent } from './features/mardom-go-admin/components/home/home.component'
import { LoginComponent } from './features/mardom-go-admin/components/session/login/login.component'
import { PasswordRecoveryComponent } from './features/mardom-go-admin/components/session/password-recovery/password-recovery.component'
import { SignupComponent } from './features/mardom-go-admin/components/session/signup/signup.component'

const routes: Routes = [
	{
		path: 'mardomgoadmin',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('../app/features/mardom-go-admin/mardom-go-admin.module').then(
				(m) => m.MardomGoAdminModule
			),
	},
	/* {
		path: ':id',
		component: BaseComponent
	}, */
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'password-recovery',
		pathMatch: 'full',
		component: PasswordRecoveryComponent,
	},
	{
		path: 'signup',
		pathMatch: 'full',
		component: SignupComponent,
	},
	{ path: 'error/:type', component: ErrorPageComponent,  data: {
		type: 'error-v1',
		code: 401,
		title: 'Acceso denegado',
		desc:
		  'El usuario que intenta ingresar no posee un rol definido, favor contactar mardomgo@mardom.com para asistencia'
	  } },
	{ path: '**', redirectTo: 'login', pathMatch: 'full' }
];
@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CorsHeaders } from '../../../core/services/corsHeader';
import { Observable, throwError } from 'rxjs';
import { endpoint } from '../../../../environments/environment';
import { Responsible } from '../models/responsible';
import { RequestType } from '../models/requestType';
import { AssingResponsibleRequestType } from '../models/assingReponsibleRequestType';
import { ChangeResponsibleRequestType } from '../models/changeReponsibleRequestType';
import { Line } from '../models/line';
import { assingResponsibleCustomer } from '../models/assingReponsibleCustomer';
import { ChangeResponsibleCustomer } from '../models/changeReponsibleCustomer';
import { ResponsibleRequestTypeLine } from '../models/responsibleRequestTypeLine';
import { ResponsibleCustomer } from '../models/responsibleCustomer';
import {accountExecutive} from '../models/accountExecutive';
import {assignToCustomer} from '../models/assignToCustomer'

@Injectable({
  providedIn: 'root'
})
export class ResponsiblesService {

  constructor(private http: HttpClient, private corsHeader:CorsHeaders) {}

  getResponsibles(): Observable<Responsible[]>{
    return this.http.get<Responsible[]>(endpoint.responsibleEndPoint, {headers:this.corsHeader.headers()});
  }

  getRequestType(): Observable<RequestType[]>{
    return this.http.get<RequestType[]>(endpoint.requestTypeEndPoint, {headers:this.corsHeader.headers()});
  }

  getResponsibleRequestType(): Observable<ResponsibleRequestTypeLine[]> {    
    return this.http.get<ResponsibleRequestTypeLine[]>(endpoint.responsibleRequestTypeEndPoint, {headers:this.corsHeader.headers()});
  }
  
  getResponsibleRequestTypeById(id:string): Observable<ResponsibleRequestTypeLine> {    
    
    return this.http.get<ResponsibleRequestTypeLine>(endpoint.responsibleRequestTypeByIdEndPoint + id, {headers:this.corsHeader.headers()});
  }

  assignRequestTypeToResponsible(responsibleRequestType : AssingResponsibleRequestType):Observable<AssingResponsibleRequestType>{
    return this.http.post<AssingResponsibleRequestType>(endpoint.assignRequestTypeToResponsibleEndPoint, responsibleRequestType, {headers:this.corsHeader.headers()});
  }

  changeRequestTypeToResponsible(responsibleRequestType : ChangeResponsibleRequestType):Observable<ChangeResponsibleRequestType>{
    return this.http.put<ChangeResponsibleRequestType>(endpoint.changeRequestTypeToResponsibleEndPoint, responsibleRequestType, {headers:this.corsHeader.headers()});
  }
  
  getLines(): Observable<Line[]>{
    return this.http.get<Line[]>(endpoint.lineEndPoint, {headers:this.corsHeader.headers()});
  }
  
  getLinesByRequestTypeId(requestTypeId: string): Observable<Line[]>{
    return this.http.get<Line[]>(endpoint.lineByRequestTypeIdEndPoint + requestTypeId, {headers:this.corsHeader.headers()});
  }

  getResponsibleCustomer(): Observable<ResponsibleCustomer[]> {    
    return this.http.get<ResponsibleCustomer[]>(endpoint.responsibleCustomerEndPoint, {headers:this.corsHeader.headers()});
  }

  getResponsibleCustomerById(id:string): Observable<ResponsibleCustomer> {        
    return this.http.get<ResponsibleCustomer>(endpoint.responsibleCustomerByIdEndPoint + id, {headers:this.corsHeader.headers()});
  }

  assignResponsibleToCustomer(responsibleCustomer : assingResponsibleCustomer){
    return this.http.post<assingResponsibleCustomer>(endpoint.assignResponsibleToCustomerEndPoint, responsibleCustomer, {headers:this.corsHeader.headers()});
  }

  changeResponsibleToCustomer(responsibleCustomer : ChangeResponsibleCustomer){
    return this.http.put<assingResponsibleCustomer>(endpoint.changeResponsibleToCustomerEndPoint, responsibleCustomer, {headers:this.corsHeader.headers()});
  }

  getResponsibleUser(): Observable<accountExecutive> {        
    return this.http.get<accountExecutive>(endpoint.employeeAccount, {headers:this.corsHeader.headers()});
  }
  
  assignExceutiveToCustomer(assignToCustomer:assignToCustomer){
    return this.http.post<assignToCustomer>(endpoint.assignToCustomer, assignToCustomer, {headers:this.corsHeader.headers()});
  }

  sendEmail(payload){
    return this.http.get<assignToCustomer>(`${endpoint.sendMail}email=${payload.email}&empresa=${payload.empresa}&fullName=${payload.fullName}&rnc=${payload.rnc}`, {headers:this.corsHeader.headers()});
  }
}

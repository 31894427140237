import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { endpoint } from '../../../../../src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../src/app/core/reducers';
import { selectAuthState } from '../../../../../src/app/core/auth/_selectors/auth.selectors';
import * as jwt_decode from "jwt-decode";
import { CorsHeaders } from '../../../../../src/app/core/services/corsHeader';

@Injectable({
    providedIn: 'root'
  })
export class UserAccountService {  

    constructor(private _httpClient: HttpClient, private appState: Store<AppState>, private corsHeader:CorsHeaders) {                       
    }

    assignRole(userId:string, role: any[]){
        return this._httpClient.post<any>(endpoint.userAccountEndpoint+`/${userId}/roles`, role, {headers: this.corsHeader.headers()});
    }

    getRoleByUserId(userId:string){
        return this._httpClient.get<any>(endpoint.userAccountEndpoint+`/${userId}/roles`, {headers: this.corsHeader.headers()});
    }

    getCurrentUserRole(){
        let token = '';
        this.appState.select(selectAuthState).subscribe(
            result => { 
                token = result.authToken;
            })
        const currentData = jwt_decode(token);
        return this._httpClient.get<any>(endpoint.userAccountEndpoint+`/${currentData.sub}/roles`, {headers: this.corsHeader.headers()});
    }    

    deleteCurrentRoles(userId:string, role: any[]) {
        return this._httpClient.put<any>(endpoint.userAccountEndpoint+`/${userId}/roles`, role, {headers: this.corsHeader.headers()});
    }

    getUserPermission(userId: string){              
        return this._httpClient.get<any>(endpoint.userAccountEndpoint+`/${userId}/permissions`, {headers: this.corsHeader.headers()});
    }

    assignPermission(userId:string, permissions: any[]){
        return this._httpClient.post<any>(endpoint.userAccountEndpoint+`/${userId}/permissions`, permissions, {headers: this.corsHeader.headers()});  
    }

    deletePermission(userId:string, permissions: any[]) {
        return this._httpClient.put<any>(endpoint.userAccountEndpoint+`/${userId}/permissions`, permissions, {headers: this.corsHeader.headers()});
    }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { endpoint, config } from '../../../../environments/environment'
import { internaluser } from '../models/internal-users/internaluser';
import { Observable } from 'rxjs';
import { AppState } from '../../../../app/core/reducers';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../../../app/core/auth/_selectors/auth.selectors';
import { CorsHeaders } from '../../../../app/core/services/corsHeader';

@Injectable({
  providedIn: 'root'
})
export class EmployeesaccountService {

  private token: string;
  constructor(private http: HttpClient, private corsHeader: CorsHeaders, private appState: Store<AppState>) {
    this.appState.select(selectAuthState).subscribe((result) => {
    this.token = result.authToken;
  });}
  
  getEmployeeAccounts(): Observable<internaluser[]> {
    return this.http.get<internaluser[]>(endpoint.employeeAccount, { headers: this.corsHeader.headers() })
  }
  getEmployeeAccountById(id: string): Observable<internaluser> {   
    return this.http.get<internaluser>(`${endpoint.employeeAccount}/${id}`, { headers: this.corsHeader.headers() })
  }
  employeeAccountManagement(id: string, state: boolean) {   
    const headers = new HttpHeaders()
    .append("Authorization", `Bearer ${this.token}`)
    .append('Content-Type', 'application/json-patch+json')
    .append('accept', 'application/json')
    .append('accept', 'text/plain')
    .append('accept', '*/*')
    .append('state', `${state}`)   
    return this.http.patch(`${endpoint.employeeAccount}/${id}`,null,{ headers: headers})
  }
  createEmployeeAccounts(id: string): Observable<internaluser[]> {
    return this.http.post<any>(`${endpoint.employeeAccount}/${id}`, {}, { headers: this.corsHeader.headers() })
  }
}



import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../../core/auth/_services';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',
    './css/bootstrap.min.css',
    './css/bootstrap.min.2.css',
    './css/bootstrap.min.3.css',
    './css/fonts.css',
    './css/icons.css',
    './css/media.css',
    './css/root.css']
})
export class LoginComponent implements OnInit {
  userFormGroup: FormGroup
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
  email: string = '';
  password: string = '';
  submitted = false
  hasError: boolean
  errorMessage: string
  isPasswordVisible: boolean
  public saveCredential:boolean = false

  constructor(private authService: AuthService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,

  ) {     this.isPasswordVisible = false
  }

  ngOnInit() {
    this.getform()
    this.getCookie()
  }

  getform(){
    this.userFormGroup = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
      password: new FormControl('', [
        Validators.required
      ]),
    })
  }

  getCookie(){
    const username = ('; '+document.cookie).split(`; username=`).pop().split(';')[0];
    const password = ('; '+document.cookie).split(`; password=`).pop().split(';')[0];
    const checked = ('; '+document.cookie).split(`; checked=`).pop().split(';')[0];
    this.saveCredential = checked == 'true'?true:false;
    if(username && password){
      this.email = username
      this.password = password
     // this.logIn(this.email,  this.password)
    }
  }

  login() {
    localStorage.removeItem('Selectedcompany');
    this.submitted = true
    if (this.userFormGroup.invalid) {
      return
    }
    const userCredentials = this.userFormGroup.value as { email; password }
    localStorage.setItem('emial',userCredentials.email)
    this.logIn(userCredentials.email, userCredentials.password)
  }


  private showErrorMessage(message: string) {
    this.errorMessage = message
    this.hasError = true
    this.cd.detectChanges();

  }

  private async logIn(email: string, password: string) {
    try {
      await this.authService.login("mardomgoadmin/dashboard", true, this.email, this.password)
    } catch (error) {
      this.showErrorMessage(error)
    }
  }

  setCookie(event){
    if(event.target.checked){
      document.cookie = "username="+this.userFormGroup.value.email;
      document.cookie = "password="+this.userFormGroup.value.password;
      document.cookie = "checked="+true;
    }else{
      document.cookie = "username="+"";
      document.cookie = "password="+"";
      document.cookie = "checked="+false;
    }
  }

  onChangeEvent(event) {
    const username = ('; '+document.cookie).split(`; username=`).pop().split(';')[0];
    if (username != event.target.value) {
      this.saveCredential = false;
      let check =  document.getElementById("checkbox") as HTMLInputElement;
      check.checked = false
      document.cookie = "username=" + "";
      document.cookie = "password=" + "";
      document.cookie = "checked=" + false;
      this.cd.detectChanges();
    }
  }

  hideErrorMessage() {
    this.hasError = false
  }
  showPassword() {
    this.isPasswordVisible = !this.isPasswordVisible
  }

}

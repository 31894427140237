import { Injectable } from '@angular/core';
import { Country } from '../../../../core/auth/_models/Country'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { IndustryType } from '../../../../core/auth/_models/IndustryType'
import { PersonTitle } from '../../../../core/auth/_models/personTitle'
import { endpoint } from '../../../../../environments/environment'
import { config } from '../../../../../environments/environment'
import { CorsHeaders } from '../../../../core/services/corsHeader';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private http: HttpClient,
    private corsHeader: CorsHeaders
    ) {}

  getCountries(): Observable<Country[]> {
    return this.http.get<any>(
			`${endpoint.getCountry}`,
			{ headers: this.corsHeader.headers() }
		)
  }

  verifyRNC(no): Observable<Country[]> {
    return this.http.get<Country[]>(
      'https://api.digital.gob.do/v1/contributors/' + no + '/info/basic'
    )
  }

  sendEmail(email) {
    return this.http.get<any>(
			`${config.ApiUrl}/logistic-warehouse/Home/SendAuthEmail?emailId=${email}`,
			{ headers: this.corsHeader.headers() }
		)
  }
  

  sendSuccessfullRegistrationEmail(payload) {
    return this.http.get<any>(
			`${config.ApiUrl}/logistic-warehouse/Home/SendSuccessfullRegistrationEmail?email=${payload.email}&username=${payload.name}
      &emailAccount=${payload.emailAccount}&companyName=${payload.companyName}`,
			{ headers: this.corsHeader.headers() }
		)
  }

  checkEmailExit(email) {
    return this.http.get<any>(
			`${config.ApiUrl}/logistic-warehouse/Home/ValidateEmail?EmailId=${email}`
		)
  }

  //**********Country* */
  getIndustryTypes(): Observable<IndustryType[]> {
    return this.http.get<any>(
			`${endpoint.industryType}`,
			{ headers: this.corsHeader.headers() }
		)
  }

  //**person */
  getPersonTitle(): Observable<PersonTitle[]> {
    return this.http.get<any>(
			`${endpoint.personTitle}`,
			{ headers: this.corsHeader.headers() }
		)
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Customer } from '../../../models/customer';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponsiblesService } from '../../../services/responsiblesServices.service';
import { AlertService } from '../../../../../core/services/alert.service';
import { Responsible } from '../../../models/responsible';
import { ChangeResponsibleCustomer } from '../../../models/changeReponsibleCustomer';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponsibleRequestTypeLine } from '../../../models/responsibleRequestTypeLine';
import { ResponsibleCustomer } from '../../../models/responsibleCustomer';

@Component({
  selector: 'kt-responsible-customer-edit',
  templateUrl: './responsible-customer-edit.component.html',
  styleUrls: ['./responsible-customer-edit.component.scss']
})
export class ResponsibleCustomerEditComponent implements OnInit {
  responsibleFormGroup: FormGroup;
  ResponsibleCustomer: ChangeResponsibleCustomer;
  ResponsibleCustomerResult: ResponsibleCustomer;
  responsibleRequestType: ResponsibleRequestTypeLine[];
  responsibleName:string;
  customerName = '';
  customerId = '';
  private id: string;

  constructor(private formsBuilder: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private responsibleService: ResponsiblesService,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService,
    ) { }

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      if (params["id"] == undefined || params["id"] == 0) {
        this.alertService.error("No se pudo obtener el id de la relacion Responsable y Cliente, será redireccionado al listado", "Aviso!")
          .then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/responsible-customer"]) } })
      }
      this.id = params["id"];
    })

    this.responsibleFormGroup = this.formsBuilder.group({
      id: 0,
      responsibleRequestTypeId: ['', Validators.required],
      customerId: ['', Validators.required],
      mainResponsible: [false]
    });
    
    this.getResponsibleRequestType();    
  }

  getResponsibleRequestType() {
    this.spinnerService.show()
    this.responsibleService.getResponsibleRequestType().subscribe(
      (list) => {
        this.responsibleRequestType = list,
        this.getResponsibleCustomer(this.id),
        this.spinnerService.hide()
      },
      (error) => {
        this.spinnerService.hide()
        switch (error.status) {
          case 401:
            return this.alertService.error(
              'No tiene los permisos para consultar este recurso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case 403:
            return this.alertService.error(
              'No tiene los permisos para consultar este recurso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case 500:
            return this.alertService.error(
              'Oop! Ha ocurrido un error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case 0:
            return this.alertService.error(
              'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case undefined:
            return this.alertService.error(
              'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
        }
      }
    );
  }

  getResponsibleCustomer(id: string) {
    this.responsibleService.getResponsibleCustomerById(this.id)
      .subscribe((response) => {
        this.loadForm(response)        
      },
        (error) => (error: { status: any; }) => {
          switch (error.status) {
            case 401:
              return this.alertService.error(
                'No tiene los permisos para consultar este recurso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
            case 403:
              return this.alertService.error(
                'No tiene los permisos para consultar este recurso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
            case 500:
              return this.alertService.error(
                'Oop! Ha ocurrido un error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
            case 0:
              return this.alertService.error(
                'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
            case undefined:
              return this.alertService.error(
                'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          }
        });
  }

  loadForm(ResponsibleCustomerResult: ResponsibleCustomer) {
    this.responsibleFormGroup.patchValue({
      id: ResponsibleCustomerResult.id,
      responsibleRequestTypeId: ResponsibleCustomerResult.responsibleRequestTypeId,
      customerId: ResponsibleCustomerResult.customerId,
      mainResponsible: ResponsibleCustomerResult.mainResponsible,
      active: ResponsibleCustomerResult.active
    });
    
    this.customerId = ResponsibleCustomerResult.customerId;
    this.customerName = ResponsibleCustomerResult.customerName;
  }

  onChange($event) {
    this.responsibleName = $event.target.options[$event.target.options.selectedIndex].text;
  }

  onSubmit() {
    if (this.responsibleFormGroup.invalid) {
      return;
    }
    this.changeResponsible();
  }

  changeResponsible() {
    this.ResponsibleCustomer = this.responsibleFormGroup.value as ChangeResponsibleCustomer;
    this.ResponsibleCustomer.id = this.id;
    this.ResponsibleCustomer.active = true;

    this.responsibleService.changeResponsibleToCustomer(this.ResponsibleCustomer)
      .subscribe(
        ResponsibleCustomer => this.onSaveSuccess(),
        (error) => {
          switch (error.status) {
            case 401:
              return this.alertService.error(
                'No posee los permisos para realizar esta acción').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/responsible-customer"]) } })
            case 403:
              return this.alertService.error(
                'No posee los permisos para realizar esta acción').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/responsible-customer"]) } })
            case 500:
              return this.alertService.error(
                'Oop! Ha ocurrido un error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
            case 0:
              return this.alertService.error(
                'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
            case undefined:
              return this.alertService.error(
                'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          }
        });
  }

  onSaveSuccess() {
    this.alertService.info('Cambios realizados satisfactoriamente')
      .then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/responsible-customer"]) } });
  }
}
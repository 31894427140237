import { Component, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from '../../services/role.service'
import { UserAccountService } from '../../services/user-account.service';
import { AlertService } from '../../../../../../src/app/core/services/alert.service';
import { AddPermissionToUserComponent } from '../add-permission-to-user/add-permission-to-user.component';
import { config } from '../../../../../../src/environments/environment';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { IRoleModel } from '../../models/role.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { LogService } from '../../services/log.service';
import { Log } from '../../models/log';
import { Action } from '../../models/enum/action';
import { Screen } from '../../models/enum/screen';

@Component({
    templateUrl: './add-role-to-user.component.html',
    styleUrls: ['./add-role-to-user.component.css']
})

export class AddRoleToUserComponent implements OnInit {

    @Output() Save = new EventEmitter();
    RoleList: IRoleModel[] = []
    RoleAssigned: any[] = []
    hasRole: boolean = false
    UserId: string
    config: any;
    isEditable:boolean = false

    displayedColumns = ['description', 'option'];
    dataSource: MatTableDataSource<IRoleModel>;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private activeModal: NgbActiveModal,
        private roleService: RoleService,
        private userAccountService: UserAccountService,
        private alertService: AlertService,
        private modelService: NgbModal,
        private spinnerService: NgxSpinnerService,
        private logService : LogService) {
        this.config = config;
    }

    ngOnInit(): void {
        this.spinnerService.show();
        this.roleService.getRoles().subscribe(
            res => {
                this.RoleList = res;
                this.userAccountService.getRoleByUserId(this.UserId).subscribe(
                    res => {
                        if (res.length > 0) {
                            this.hasRole = true
                            this.RoleAssigned = res
                        }else{
                            this.isEditable = true
                        }
                    }
                );
                this.dataSource = new MatTableDataSource(res);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.spinnerService.hide();
            },
            error => {
                console.log(error);
                this.spinnerService.hide();
                this.alertService.error("Ha ocurrido un error al buscar el listado de roles", "error");
            }
        );
    }

    get roles() {
        let role = '';
        let countElement = 0;
        this.RoleAssigned.map(item => {
            role += item.description;
            countElement++;
            if (this.RoleAssigned.length > countElement) {
                role += ', ';
            }
        })
        return role;
    }

    selectedImported(item: any) {
        item.selected = !item.selected;
    }

    addPermissions() {
        if (this.RoleAssigned.length == 1) {
            let modal = this.modelService.open(AddPermissionToUserComponent, this.config.modalConfig);
            modal.componentInstance.roleId = this.RoleAssigned[0].id;
            modal.componentInstance.userId = this.UserId;
            modal.componentInstance.roleName = this.RoleAssigned[0].description;
            modal.componentInstance.onlyPermissions = true;
            modal.componentInstance.Save.subscribe(async (data: any) => {
                this.spinnerService.show();
                this.assignPermissions(data);
                this.spinnerService.hide();
                this.logService.post(<Log>{
                    ScreenId: Screen.EjecutivoInterno,
                    ActionId:  Action.AsignacionDePermisos ,
                    Json: '',
                })
                this.alertService.success("Los permisos fue asignado correctamente", "Operación exitosa").then(
                    () => {
                        this.cancel()
                    }
                )
                modal.close();
            })
        }
    }

    selectRole(role: any) {
        let modal = this.modelService.open(AddPermissionToUserComponent, this.config.modalConfig);
        modal.componentInstance.roleId = role.id;
        modal.componentInstance.userId = this.UserId;
        modal.componentInstance.roleName = role.description;
        modal.componentInstance.Save.subscribe(async (data: any) => {            
            this.validateRoleOperation(role).then(
                (operationResult:boolean) => {                    
                    this.spinnerService.show();
                    if (operationResult) {                        
                        this.assignPermissions(data);
                        modal.close();
                        this.spinnerService.hide();
                        this.logService.post(<Log>{
                            ScreenId: Screen.EjecutivoInterno,
                            ActionId:  Action.AsignacionDeRol ,
                            Json: '',
                        })
                        this.alertService.success("El rol fue asignado correctamente", "Operación exitosa").then(
                            () => {                                                                
                                this.cancel()
                            }
                        )
                    } else {
                        this.spinnerService.hide();
                    }
                }
            );
        });
    }

    assignPermissions(data: any) {        
        let operationResult = false;
        const dataConverted = JSON.parse(data);
        const permissions = [];
        const permissionsToBeAdd = [];
        const permissionsToBeDelete = [];        

        dataConverted.newPermissions.map(item => {
            permissions.push({
                scope: item.name ? item.name : item.scope,
                description: item.description
            });
        })

        permissions.map(per => {
            const isFound = dataConverted.originalPermissions.find(p => per.scope === p.name);
            if (isFound == undefined)
                permissionsToBeAdd.push(per)
        });

        dataConverted.originalPermissions.map(per => {
            const isFound = permissions.find(p => per.name === p.scope);
            if (isFound == undefined) {
                permissionsToBeDelete.push(per)
            }
        });
        
        if (permissionsToBeDelete.length > 0) {
            this.userAccountService.deletePermission(this.UserId, permissionsToBeDelete).subscribe(
                _res => {
                    this.userAccountService.assignPermission(this.UserId, permissionsToBeAdd).subscribe(
                        _res => {
                            operationResult = true;
                        },
                        error => {
                            console.log(error);
                            this.alertService.error("Ha ocurrido un error al asignar los permisos al usuario", "error");
                        }
                    );
                },
                error => {
                    console.log(error);
                    this.alertService.error("Ha ocurrido un error al asignar los permisos al usuario", "error");
                }
            );
        }

        if (permissionsToBeAdd.length > 0 && !operationResult) {
            this.userAccountService.assignPermission(this.UserId, permissionsToBeAdd).subscribe(
                _res => {
                    operationResult = true;
                },
                error => {
                    console.log(error);
                    this.alertService.error("Ha ocurrido un error al asignar los permisos al usuario", "error");
                }
            );
        }

    }

    validateRoleOperation(role: any) {
        return new Promise((resolve, reject) => {
            let operationResult = false;
            const rolesToAssign = []
            let isSameRole = false
            rolesToAssign.push(role)

            if (this.RoleAssigned.length == 1) {
                this.RoleAssigned.map(role => {
                    const result = rolesToAssign.find(responseItem => responseItem.id === role.id);
                    if (result)
                        isSameRole = true;
                })
            }

            if (isSameRole) {
                this.alertService.info("El usuario ya posee el rol que intenta asignar", "Info");
                return;
            }

            if (this.hasRole) {
                this.alertService.question(() => {
                    this.userAccountService.deleteCurrentRoles(this.UserId, this.RoleAssigned).subscribe(
                        _res => {
                            this.userAccountService.assignRole(this.UserId, rolesToAssign).subscribe(
                                _res => {
                                    resolve(true);
                                },
                                error => {
                                    console.log(error);
                                    this.alertService.error("Ha ocurrido un error al asignar el rol al usuario", "error");
                                    reject(false);
                                }
                            )
                        }
                    )
                }, "Este usuario ya posee un rol asignado, ¿Deseas asignar el rol como principal?");
            } else {
                this.userAccountService.assignRole(this.UserId, rolesToAssign).subscribe(
                    _res => {
                        resolve(true);
                    },
                    error => {
                        console.log(error);
                        this.alertService.error("Ha ocurrido un error al asignar el role al usuario", "error");
                        reject(false);
                    }
                )
            }
        });
    }

    cancel() {
        this.activeModal.close();
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }
}
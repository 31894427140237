// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import {
  AuthActionTypes,
  Login,
  Logout,
  Register,
  UserLoaded,
  UserRequested
} from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded } from '../_selectors/auth.selectors';
import { BeginLogin, RefreshTokens } from '../_actions/auth.actions';
import { OnInitEffects } from '@ngrx/effects';
import * as jwt_decode from 'jwt-decode';
import { AllPermissionsRequested } from '../_actions/permission.actions';

@Injectable()
export class AuthEffects implements OnInitEffects {
  @Effect({ dispatch: false })
  login$ = this.actions$.pipe(
    ofType<Login>(AuthActionTypes.Login),
    tap(action => {
      localStorage.setItem(
        environment.accessTokenKey,
        action.payload.accessToken
      );
      localStorage.setItem(environment.idTokenKey, action.payload.idToken);
      localStorage.setItem('isLoggingIn', '0');
      this.store.dispatch(new UserRequested());
    })
  );

  @Effect({ dispatch: false })
  beginLogin$ = this.actions$.pipe(
    ofType<BeginLogin>(AuthActionTypes.BeginLogin),
    tap(() => {
      localStorage.setItem('isLoggingIn', '1');
    })
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(() => {
      localStorage.removeItem(environment.accessTokenKey);
      localStorage.removeItem(environment.idTokenKey);
      localStorage.removeItem(environment.tokensExpirationDateKey);
      this.auth.logout();      
    })
  );

  @Effect({ dispatch: false })
  register$ = this.actions$.pipe(
    ofType<Register>(AuthActionTypes.Register),
    tap(action => {
      localStorage.setItem(
        environment.accessTokenKey,
        action.payload.authToken
      );
    })
  );

  @Effect({ dispatch: false })
  loadUser$ = this.actions$.pipe(
    ofType<UserRequested>(AuthActionTypes.UserRequested),
    withLatestFrom(this.store.pipe(select(isUserLoaded))),
    filter(([action, _isUserLoaded]) => !_isUserLoaded),
    mergeMap(([action, _isUserLoaded]) => this.auth.getUser()),
    tap(_user => {
      if (_user) {
        this.store.dispatch(new UserLoaded({ user: _user }));
        this.store.dispatch(new AllPermissionsRequested());
      } else {
        this.store.dispatch(new Logout());
      }
    })
  );

  @Effect({ dispatch: false })
  refreshTokens$ = this.actions$.pipe(
    ofType<RefreshTokens>(AuthActionTypes.RefreshTokens),
    tap(() => {
      this.store.dispatch(new UserRequested());
    })
  );

  @Effect()
  init$: Observable<Action> = defer(() => {
    const accessToken = localStorage.getItem(environment.accessTokenKey);
    const idToken = localStorage.getItem(environment.idTokenKey);
    let observableResult = of({ type: 'NO_ACTION' });
    if (accessToken && idToken) {
      if (this.auth.validateTokenLifetime(accessToken)) {
        observableResult = of(new Login({ accessToken, idToken }));
      } else {
        this.auth.refreshTokens();
      }
    }
    return observableResult;
  });

  ngrxOnInitEffects(): Action {
    const isLoggingIn = localStorage.getItem('isLoggingIn');
    if (isLoggingIn === '1') {
      return { type: AuthActionTypes.BeginLogin };
    }
    return { type: 'NO_ACTION' };
  }

  private returnUrl: string;

  constructor(
    private actions$: Actions,
    private router: Router,
    private auth: AuthService,
    private store: Store<AppState>
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
      }
    });
  }
}

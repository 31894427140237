import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {

  @ViewChild('companyModal', { static: true }) companyModal: TemplateRef<any>;
  @ViewChild('empresaSuccess', { static: true }) empresaSuccess: TemplateRef<any>;
  @ViewChild('userModal', { static: true }) userModal: TemplateRef<any>;

  @Output() onReturnAction: EventEmitter<any> = new EventEmitter<any>();
  @Input() editData;
  public addCompanyForm: FormGroup;
  public CountryList: any;
  public checkValidation: boolean = false;
  public rnc: boolean = false;
  public companyList: any;
  public isDisabled:boolean;
  public userData: any;
  public showdata:boolean=false;

  public singleSelectOptions = {
    width: '100%',
    multiple: false,
    placeholder: 'Selecccionar...',
  }
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private chRef:ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.generateForm()
  }

  public StaticData:any= [
    { Name:"Maria teresa Ramirez", EmailId: "Test1@siddhatech.com",RncNumber:"THYP252", Address:"Edif. A Calle 114 Av. Universidad Miami", UserRole:"CONSOLIDADOR", Estatus:"ACTIVE"},
    { Name:"Daria teresa Ramirez",EmailId: "Test2@siddhatech.com",RncNumber:"THYP252", Address:"Edif. A Calle 114 Av. Universidad Miami", UserRole:"CORDINATOR", Estatus:"INACTIVO"}, 
    { Name:"Taria teresa Ramirez",EmailId: "Test3@siddhatech.com",RncNumber:"THYP252", Address:"Edif. A Calle 114 Av. Universidad Miami", UserRole:"CORDINATOR" , Estatus:"ACTIVE"}, 
    { Name:"Yaria teresa Ramirez",EmailId: "Test4@siddhatech.com" ,RncNumber:"THYP252", Address:"Edif. A Calle 114 Av. Universidad Miami", UserRole:"CORDINATOR", Estatus:"INACTIVO"}];
    
  returnAction(){
    this.onReturnAction.emit();
  }

  openCompanyModal() {
    this.modalService.open(this.companyModal, {
      size: 'lg', backdrop: 'static', centered: true
    });
    
  }

  openUserModal() {
    this.modalService.open(this.userModal, {
      size: 'lg', backdrop: 'static', centered: true
    });
    
  }
  empresaSuccessModal() {
    this.modalService.open(this.empresaSuccess, {
      size: 'sm', backdrop: 'static', centered: true
    });
  }

  closeCompanyModal() {
    this.modalService.dismissAll();
  }

  generateForm() {
    this.addCompanyForm = this.fb.group({
      Country: ['', [Validators.required]],
      rncNo: ['', [Validators.required]],
      commercialName: ['', [Validators.required]],
      RoleInTheCompany: ['', [Validators.required]],
    });
  }

 
  cerrar() {
    this.modalService.dismissAll();
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kt-associate-empresa',
  templateUrl: './associate-empresa.component.html',
  styleUrls: ['./associate-empresa.component.scss']
})
export class AssociateEmpresaComponent implements OnInit {
  @Output() newUserDetails = new EventEmitter<any>();

	companies = false;
	loading = false
  selectedUserList:any=[]
  constructor() { }

  ngOnInit() {
  }
  getCompany(){
    this.companies = true;

  }

  senddata(){
    let data={
      customerId:'123456789',
      customerInfo:'Test Details',
      customerRole:'Admin'
    }
    this.selectedUserList.push(data)
      this.newUserDetails.emit(this.selectedUserList);
  }
}

import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-horizontal-stepper',
  templateUrl: './horizontal-stepper.component.html',
  styles: [`
    .mat-stepper-horizontal {
        margin-top: 8px;
    }
    .mat-form-field {
        margin-top: 16px;
    }`],
  styleUrls:['./horizontal-stepper.component.scss']
})
export class HorizontalStepperComponent {
  @ViewChild('updateSuccess', { static: true }) updateSuccess: TemplateRef<any>
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  NewUserDetails:any;

  constructor(private _formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }
  ngOnInit() {   
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }
  close() {
    this.activeModal.close()
  }

  cerrar() {
    this.modalService.dismissAll();
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  empresaSuccessModal() {
    this.modalService.open(this.updateSuccess, {
      size: 'sm', backdrop: 'static', centered: true
    });
  }

  getNewUserDetails(NewUserDetails) {
    this.NewUserDetails=NewUserDetails;
   console.log(NewUserDetails)
  }

 }

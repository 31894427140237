import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { AlertService } from '../../../../../core/services/alert.service';
import { ResponsiblesService } from '../../../services/responsiblesServices.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ResponsibleCustomer } from '../../../models/responsibleCustomer';

@Component({
  selector: 'kt-responsible-customer',
  templateUrl: './responsible-customer.component.html',
  styleUrls: ['./responsible-customer.component.scss']
})
export class ResponsibleCustomerComponent implements OnInit {
  ResponsibleCustomer: ResponsibleCustomer[];
  searchText;
  displayedColumns = ['customer', 'responsible', 'requestType', 'line', 'mainResponsible', 'options'];
  dataSource: MatTableDataSource<ResponsibleCustomer>;
  listFilters = [];
  filterValue = '';
  selectedFilter = '';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private router: Router,
    private ResponsibleCustomerService: ResponsiblesService, 
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService,
    ) { }

  ngOnInit() {
    this.getResponsibleCustomer();
    this.getFilterCriteria();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;

    if (this.dataSource.filteredData.length == 0) {
      this.alertService.info("Responsable no encontrado");
    }
  }

  getResponsibleCustomer() {
    this.spinnerService.show()
    this.ResponsibleCustomerService.getResponsibleCustomer().subscribe(
      (list) => {
        this.ResponsibleCustomer = list
        this.dataSource = new MatTableDataSource(this.ResponsibleCustomer)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinnerService.hide()
      },
      (error) => {
        this.spinnerService.hide()
        switch (error.status) {
          case 401:
            return this.alertService.error(
              'No tiene los permisos para consultar este recurso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case 403:
            return this.alertService.error(
              'No tiene los permisos para consultar este recurso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case 500:
            return this.alertService.error(
              'Oop! Ha ocurrido un error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case 0:
            return this.alertService.error(
              'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case undefined:
            return this.alertService.error(
              'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
        }
      }
    );
  }
  
  getFilterCriteria() {
    this.listFilters = [
      { id: 'customer', value: 'Cliente' },
      { id: 'responsible', value: 'Responsable' },
      { id: 'requestType', value: 'Tipo Solicitud' },
      { id: 'line', value: 'Linea' },
    ]
  }

  searchByCriteria() {
    this.dataSource.filterPredicate = (data: ResponsibleCustomer, filter: string) => {
      if(this.selectedFilter == 'customer'){
        return data.customerName.toLowerCase().indexOf(filter.toLowerCase()) != -1;
      }else if(this.selectedFilter == 'responsible'){
        return data.responsibleName.toLowerCase().indexOf(filter.toLowerCase()) != -1;
      } else if(this.selectedFilter == 'requestType'){
        return data.requestType.toLowerCase().indexOf(filter.toLowerCase()) != -1;
      }else if(this.selectedFilter == 'line'){
        return data.lineName.toLowerCase().indexOf(filter.toLowerCase()) != -1;
      }
    };
    this.dataSource.filter = (this.filterValue);
  }

  clean() {
    this.filterValue = ''
    this.selectedFilter = ''
    this.dataSource = new MatTableDataSource(this.ResponsibleCustomer);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerMardomgoService } from '../../../services/customer.service'
import { DatashareService} from '../../../services/datashare.service'
import { NgxSpinnerService } from 'ngx-spinner'
import { AlertService } from '../../../../../core/services/alert.service';
@Component({
  selector: 'kt-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {
  public condition: boolean = false;
  public sendDeta: boolean = false;
  public page: number = 1;
  public totalLength: number = 4;
  public userManagement: boolean = true;
  public userDetails: boolean = false;
  public editData: any;
  filterValue = ''
  selectedFilter = ''
  filter: any = {}
  public userlist:any=[]
  public CustomerDetails:any;
  userEmailId;
  userName;
  type;
 
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private customerService: CustomerMardomgoService,
    private datashareService:DatashareService,
    private ngxSpinnerService:NgxSpinnerService,
    private alertService:AlertService

  ) { }

  ngOnInit() {
    this.CustomerDetails=this.datashareService.customerDetails;
  //this.callApi();
  let type=sessionStorage.getItem('typeofRole').split('  ')
  this.type=type[0];
  this.userEmailId=type[1];
  this.userName=type[2];
  if(!this.CustomerDetails  || !type){
   return this.router.navigate(["/mardomgoadmin/customer"]) 
  }else{
    this.getuserlist();
  }
  }


  callApi() {

    this.customerService.getCompany('7', 'accepted').subscribe(
      (response) => {
        //		this.loading = false
        console.log(response)
      },
      (error) => {
        //		this.loading = false
        console.log(error)
      }
    )
    let payload = {
      Email: 'siddha1@Mardom.com',
      Empresa: 'Mardom',
    }

    this.customerService.sendMail(payload).subscribe(response => {
      console.log(response);
    }
      , (error) => {
        console.log(error);
      })
  }


  sendMail() {
    let payload = {
      Email: 'siddha1@Mardom.com',
      Empresa: 'Mardom',
    }

    this.customerService.sendMail(payload).subscribe(response => {
      console.log(response);
    }
      , (error) => {
        console.log(error);

      })
  }

  clean() {
    this.filterValue = ''
    this.selectedFilter = null
    this.filter.active = null
  }

  cerrar() {
    this.modalService.dismissAll();
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  getuserlist(){
   this.ngxSpinnerService.show();
    let userId=this.CustomerDetails.id;
    this.customerService.GetUserlistUserId(userId).subscribe((response)=>{
      this.userlist=response;
     this.ngxSpinnerService.hide();
    },(error)=>{
      this.alertService.error('Ha ocurrido un error, favor intente de nuevo.');
      this.ngxSpinnerService.hide();
    })
  }

  assignrole(item, role){
    const rl1=item.roles==null?'':item.roles.find(rl=>rl=='Admin')
    const rl2=item.roles==null?'':item.roles.find(rl=>rl=='admin')
    const rl3=item.roles==null?'':item.roles.find(rl=>rl=='consolidador')
    let response={
      "userId": this.CustomerDetails.id,
      "companyid": item.companyId,
      "specialrole": "",
      "role": role,
      "status": "active"  
  }
  if(rl1=='Admin' || rl2=='admin'){
    this.alertService.info('Esta usuaria ya tiene rol de administrador.');
  }else if(rl3=='consolidador'){
    return this.alertService.info('Esta usuaria ya tiene rol de consolidador.');
  }else{
    this.ngxSpinnerService.show();
    this.customerService.assignCompanyToUser(response).subscribe((response)=>{
    this.ngxSpinnerService.hide();
    this.alertService.success('Exito!.','success').then(() => {
      window.location.reload(); })
  },(error)=>{
    this.alertService.error('Ha ocurrido un error, favor intente de nuevo.');
    this.ngxSpinnerService.hide(); })
  }}

  cunfirmConsolidadorRole(item, role){
    this.alertService.question(() => {
      this.assignrole(item, role);
		}, `¿Está seguro de que desea asignar el rol de consolidador a ${this.userName} y empresa ${item.companyName}?`)
  }

  cunfirmAdminRole(item, role){
    this.alertService.question(() => {
      this.assignrole(item, role);
		}, `¿Está seguro de que desea asignar el rol de Admin a ${this.userName} y empresa ${item.companyName}?`)
  }
}

// Angular
import { Component, OnInit } from '@angular/core'
// Lodash
import { shuffle } from 'lodash'
// Services
// Widgets model
import {
	LayoutConfigService,
	SparklineChartOptions,
} from '../../../../core/_base/layout'
import { Widget4Data } from '../../../../views/partials/content/widgets/widget4/widget4.component'
import { Store } from '@ngrx/store'
import { AppState } from '../../../../core/reducers/index'
import {
	currentUser,
	currentAccessToken,
} from '../../../../core/auth/_selectors/auth.selectors'
import { AlertService } from '../../../../core/services/alert.service'
import { AddRoleToUserComponent } from '../add-role-to-user/add-role-to-user.component'
import { config } from '../../../../../environments/environment'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { NgxSpinnerService } from 'ngx-spinner'
import { LogService } from '../../services/log.service'
import { Screen } from '../../models/enum/screen'
import { Action } from '../../models/enum/action'
import { Log } from '../../models/log'

@Component({
	templateUrl: './home.component.html',
	styleUrls: ['home.component.scss'],
})
export class HomeComponent {
	constructor(
		private alertService: AlertService,
		private spinnerService: NgxSpinnerService,
		private logService: LogService
	) {
		this.logService.post(<Log>{
			ScreenId: Screen.Home,
			ActionId: Action.AccederAPantalla,
			Json: '',
		})
	}

	popAlert(type: string) {
		if (type == 'success')
			this.alertService.success('Success', 'Success Message')

		if (type == 'warning')
			this.alertService.warning('Warning', 'Warning Message')

		if (type == 'info') this.alertService.info('Info', 'Info Message')

		if (type == 'danger') this.alertService.error('Danger', 'Danger Message')
	}
}
